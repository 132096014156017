import React, { FC } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import Spacer from '../Spacer';
import BodyText from '../BodyText';
import Button from '../Button';
import Link from '../Link';

const Container = styled(Link)`
  display: flex;
  flex-direction: row;
  align-items: center;
`;


const Circle = styled.div`
  background-color: ${({ theme }) => theme.colors.gold};
  border-radius: 50%;
  width: 0.9375rem;
  height: 0.9375rem;
`;

interface CircularButtonProps {
  children: any;
  url: string;
  type?: string;
  color?: string;
}

const CircularButton: FC<CircularButtonProps>  = ({ children, url, type, color = "white" }) => {
  const isTabletUp = useBreakpoint(up('tablet-portrait'));
  return isTabletUp ? (
    <Container url={url} type={type}>
      <Circle />
      <Spacer spacing="sm4" direction="vertical" />
      <BodyText>
        {children}
      </BodyText>
    </Container>
  ) : (
    <Button url={url} type={type} color={color}>
      {children}
    </Button>
  )
};


export default CircularButton;
