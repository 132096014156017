import React, { SVGProps } from 'react';
import styled from 'styled-components';

const SVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="132"
    height="369"
    viewBox="0 0 195 69"
    fill="#001831"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0.367188 34.3602C0.367188 54.0909 16.0401 68.8075 34.3414 68.8075C52.6426 68.8075 69.1917 54.0909 69.1917 34.3602C69.1917 14.5333 53.2267 0 34.9254 0C16.6242 0 0.367188 14.2446 0.367188 34.3602ZM9.12844 34.3602C9.12844 14.5333 19.3499 0.96247 35.3148 0.96247C51.1824 0.96247 60.5278 14.5333 60.5278 34.3602C60.5278 54.0909 49.8196 67.845 33.952 67.845C18.0844 67.845 9.12844 54.0909 9.12844 34.3602Z" />
    <path d="M90.6056 21.5502C87.9772 23.2826 85.3488 25.4963 84.1806 29.7312C83.7913 25.9775 81.6496 22.4164 79.9947 20.8765C79.7027 20.5877 79.6053 20.5877 79.4106 20.7802C77.7557 22.4164 74.4459 24.1489 72.6937 24.8226C72.499 24.9188 72.4016 25.1113 72.6937 25.4001C74.9327 27.4213 77.1717 29.9237 77.1717 32.7148L77.1717 57.5466C77.1717 67.5563 76.4902 68.1338 71.7202 68.1338C70.9414 68.1338 70.9414 69 71.7202 69C74.5433 69 80.1894 68.8075 81.6496 68.8075C83.2072 68.8075 89.5348 69 90.3135 69C91.0923 69 91.0923 68.1338 90.3135 68.1338H84.3754L84.3754 35.891C84.3754 27.6138 85.0568 26.6513 92.0658 26.6513H95.6676C99.0748 26.6513 101.884 21.8481 101.884 20.3081C101.884 19.0569 98.4907 19.914 96.1544 19.914C94.2074 19.914 92.2605 20.4915 90.6056 21.5502Z" />
    <path d="M118.053 69C126.814 69 135.283 63.4177 140.638 53.3117C140.735 53.1192 141.107 51.9918 140.815 51.8955C140.523 51.7993 140.053 52.7342 139.859 53.1192C135.673 61.204 128.859 63.6102 122.531 63.6102C110.168 63.6102 101.017 54.2742 101.017 41.5696C101.017 28.48 108.708 21.0689 118.345 21.0689C127.788 21.0689 129.832 30.1162 133.921 30.1162C137.036 30.1162 138.691 27.8062 138.691 26.17C138.691 22.8014 131.974 18.9515 122.434 18.9515C108.221 18.9515 94.6897 32.0411 94.6897 45.9007C94.6897 59.4715 104.327 69 118.053 69Z" />
    <path d="M164.119 68.6242C178.819 68.6242 189.04 56.4008 194.2 44.5624C194.686 43.5037 194.005 43.2149 193.518 44.3699C188.164 55.8233 180.961 63.0419 169.084 63.0419C155.942 63.0419 145.526 53.8021 145.526 41.9637C145.526 39.2688 145.818 36.7664 146.305 34.5527L179.598 34.5527C182.615 34.5527 183.492 33.2052 183.686 31.569C184.27 27.1417 180.766 18.5757 166.748 18.5757C153.314 18.5757 139.685 31.0878 139.685 45.5249C139.685 58.807 149.225 68.6242 164.119 68.6242ZM170.447 33.3977C164.119 33.3977 157.5 33.7827 146.5 33.7827C149.031 25.1205 155.65 20.6931 164.022 20.6931C171.518 20.6931 175.217 25.7942 175.217 29.4516C175.217 30.7028 174.925 31.8578 174.341 32.5315C174.049 32.8203 171.81 33.3977 170.447 33.3977ZM160.226 15.8778C165.093 10.7767 173.367 5.29058 174.633 4.13561C175.217 3.65437 175.801 2.98065 175.801 2.49941C175.801 1.63319 175.801 0.959456 175.509 0.670718C175.314 0.478221 174.536 0.381975 173.757 0.381975C172.881 0.381975 171.907 0.478221 171.421 0.959456C170.35 2.01818 164.801 10.5842 159.739 15.3003C159.642 15.3965 159.447 15.589 159.447 15.6853C159.447 15.8778 159.642 16.0703 159.836 16.0703C160.031 16.0703 160.128 15.974 160.226 15.8778Z" />
  </svg>
);



const SvgLogo = styled(SVG);

export default SvgLogo`
  display: ${({ display = 'inline-block' }) => display};
`;
