import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const ResultWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  @media screen and (min-width: 50.9375rem) {
    flex-direction: row;
    max-width: 300px;
  }

  ${up('laptop')} {
    padding: 0 5%;
  }
`;

export const MetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;