import React, { FC } from 'react';
import { up } from 'styled-breakpoints';
import styled from 'styled-components';
import RichText from '../../components/RichText';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  padding: 1.5rem 1rem;

  ${up('phone')} {
    padding: 1.5rem;

  }

  ${up('tablet-portrait')} {
    padding: 2rem 6rem;
  }

  ${up('laptop')} {
    padding: 2rem 9.735rem;
  }

  ul {
    list-style-type: disc;
  }
`;

const BasicRichText: FC<{ data: any }> = ({ data: { primary } }) => {
  return (
    <Container>
      <RichText
        document={primary.rich_text_content.richText}
        marginBottom="md2"
      />
    </Container>
  )
};


export default BasicRichText;
