import React, { FC, Fragment } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { useInView } from 'react-intersection-observer';
import { FadeinUp } from '../../../styles/FadeinUp';
import Accordion from '../../../components/Accordion';
import RichText from '../../../components/RichText';
import BodyText from '../../../components/BodyText';
import HorizontalRuler from '../../../components/HorizontalRuler';
import GraphContent from '../GraphContent';

interface MobileScienceSectionProps {
  data: any[];
  graphData?: any;
}

const Container = styled.div<{ inView?: boolean }>`
  border-top: 1px solid ${({ theme }) => theme.colors.navy};
  ${FadeinUp};

  ${up('tablet-portrait')} {
    border-top: 1px solid ${({ theme }) => theme.colors.navy};
    border-left: 1px solid ${({ theme }) => theme.colors.navy};
    border-right: 1px solid ${({ theme }) => theme.colors.navy};
  }
`;

export const MobileResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  ${up('tablet-portrait')} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 2rem;
  }
`;

const MobileScienceSection: FC<MobileScienceSectionProps> = ({ data, graphData }) => {
  const { ref: ref1, inView: inView1 } = useInView({ threshold: 0.1});
  const graph = {
    title: 'RESULTS',
    isGraph: true,
    content: (
      <MobileResultContainer>
        <GraphContent
          percentage={graphData[0].percentage}
          description={graphData[0].description}
          delay={0}
          inView={inView1}
        />
        <GraphContent
          percentage={graphData[1].percentage}
          description={graphData[1].description}
          delay={300}
          inView={inView1}
        />
        <GraphContent
          percentage={graphData[2].percentage}
          description={graphData[2].description}
          delay={600}
          inView={inView1}
        />
      </MobileResultContainer>
    )
  };

  const graphDataWithResults = [graph, ...data];

  const formattedData = graphDataWithResults?.map((item) => {
    const title = item?.feature_title ? (
      <RichText
        document={item?.feature_title?.richText}
        textSize="f3"
        fontFamily="sourceSansBold"
      />
    ) : (
      <BodyText textSize="f3" alignment="left">
        {item.title}
      </BodyText>
    );

    const content = item?.isGraph ? (
      item?.content
    ) : (
      <RichText document={item.feature_description.richText} />
    )
    return { title, content }
  });

  return (
    <Container ref={ref1} inView={inView1}>
      {formattedData?.map((item: any, index: number) => {
        return (
          <Fragment key={`accordion-button-${index}`}>
            <Accordion
              title={item.title}
              content={item.content}
              isAccordionOpen={index === 0}
              isLast={formattedData?.length - 1 === index}
            />
            <HorizontalRuler aria-hidden />
          </Fragment>
        )
      })}
    </Container>
  )
};


export default MobileScienceSection;


