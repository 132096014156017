/* eslint-disable react/jsx-props-no-spreading */
import React, { FC, ReactNode } from 'react';
import P from 'prop-types';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { GatsbyImage } from 'gatsby-plugin-image';

interface EditorialSlidesProps {
  children: ReactNode;
  navItems: any;
}

const EditorialSlides: FC<EditorialSlidesProps> = ({ children, navItems }) => {

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    autoplay: true,
    autoplaySpeed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    dotsClass: 'publisher-logo',
    appendDots: (dots: any) => (
      <div>
        <ul>{dots}</ul>
      </div>
    ),
    customPaging: (i: any) => (
      <>
      <GatsbyImage
        image={navItems[i]}
        alt=""
        className="logo-img"
        imgStyle={{objectFit: 'contain'}}
      />
      </>
    )
  };

  return (
    <Slider {...settings}>
      {children}
    </Slider>
  );
};

EditorialSlides.propTypes = {
  children: P.node.isRequired,
  navItems: P.arrayOf(P.shape({})).isRequired,
};
export default EditorialSlides;
