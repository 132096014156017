type Value =
  | {
      node: HTMLElement;
      ariaHidden: string | null;
    }
  | undefined;

/**
 * Sets `aria-hidden` on all elements on the body, except for the active node.
 */
export default function createAriaHider(containerNode: HTMLElement, selector = 'body > :not(script)'): () => void {
  const rootNodes: Value[] = Array.from<HTMLElement>(document.querySelectorAll(selector)).map(node => {
    if (node.contains(containerNode)) {
      return undefined;
    }
    const ariaHidden = node.getAttribute('aria-hidden');
    if (ariaHidden === null || ariaHidden === 'false') {
      node.setAttribute('aria-hidden', 'true');
    }

    return { node, ariaHidden };
  });

  return (): void => {
    rootNodes.forEach(item => {
      if (!item) return;
      if (item.ariaHidden === null) {
        item.node.removeAttribute('aria-hidden');
      } else {
        item.node.setAttribute('aria-hidden', item.ariaHidden);
      }
    });
  };
}
