import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import RichText from '../../components/RichText';
import Heading from '../../components/Heading';
import Spacer from '../../components/Spacer';
import DynamicButton from '../../components/DynamicButton';

import * as S from './LabTestedResults-styled';

interface LabTestedResultProps {
  data: any;
}


const LabTestedResults: FC<LabTestedResultProps> = ({ data }) => {
  const isTabletUp = useBreakpoint(up('tablet-portrait'));

  return (
    <S.Container>
      <S.HeaderContainer>
        <Heading
          textSize={[ null, null, "f7","f9"]}
        >
          LAB-TESTED <i>results</i>
        </Heading>
        {!isTabletUp && <Spacer spacing="md3" />}
        <DynamicButton url={'/the-science'} type={"document"} color="navy">
          LEARN MORE
        </DynamicButton>
      </S.HeaderContainer>
      <Spacer spacing="lg1" />
      <S.ScrollContainer>
        <S.ScrollSubContainer>
          <S.SubContainer>
            {data?.items?.map((item: any, index: number) => (
              <S.Card key={`lab-result-${index}`}>
                <GatsbyImage image={item.lab_result_image.gatsbyImageData} alt="" />
                <Spacer spacing="md1" />
                <RichText document={item.results_description.richText} />
              </S.Card>
            ))}
          </S.SubContainer>
        </S.ScrollSubContainer>
      </S.ScrollContainer>
    </S.Container>
  )
};

export default LabTestedResults;