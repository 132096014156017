import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

interface PercentageProps {
  deg: string;
  $fill: string;
  inView?: boolean;
};

interface RadialGradientProps {
  delay: number;
}

export const CircleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 8.9625rem;
  height: 8.9625rem;

  ${up('phone')} {
    width: 11.95rem;
    height: 11.95rem;
  }

  ${up('tablet-landscape')} {
    width: 12.9375rem;
    height: 12.9375rem;
  }

`;

export const CircleWrap = styled.div`
  width: 5.625rem;
  height: 5.625rem;
  position: relative;
  background: #F5F3EE;
  border-radius: 50%;

  ${up('phone')} {
    width: 7.5rem;
    height: 7.5rem;
  }

  ${up('tablet-landscape')} {
    width: 9.375rem;
    height: 9.375rem;
  }
`;


export const CircleOutline = styled.div`
  width: 5.55rem;
  height: 5.55rem;
  position: absolute;
  border-radius: 50%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${up('phone')} {
    width: 7.4rem;
    height: 7.4rem;
  }

  ${up('tablet-landscape')} {
    width: 9.25rem;
    height: 9.25rem;
  }

  &:before {
    content: '';
    position: absolute;
    inset: -1px;
    border: 1px solid ${({ theme }) => theme.colors.gray};
    border-radius: 50%;
  }
}
`;

const animation = css<PercentageProps>`
  transform: rotate(${({ deg }) => deg});
  animation: ${({ $fill }) => $fill};
  nimation-timing-function: ease-in-out;
  animation-duration: 2s;


  @keyframes ${({ $fill }) => $fill} {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(${({ deg }) => deg});
    }
  }
`;

export const CircleFull = styled.div<PercentageProps>`
  position: absolute;
  clip: rect(0px, 90px, 90px, 45px);
  width: 5.625rem;
  height: 5.625rem;
  border-radius: 50%;

  ${({inView}) => inView ? animation : ''};

  ${up('phone')} {
    clip: rect(0px, 120px, 120px, 50px);
    width: 7.5rem;
    height: 7.5rem;
  }

  ${up('tablet-landscape')} {
    clip: rect(0px, 150px, 150px, 75px);
    width: 9.375rem;
    height: 9.375rem;
  }
`;

export const CircleHalf = styled.div`
  clip: rect(0px, 90px, 90px, 45px);
  width: 5.625rem;
  height: 5.625rem;
  position: absolute;
  border-radius: 50%;

  ${up('phone')} {
    clip: rect(0px, 120px, 120px, 50px);
    width: 7.5rem;
    height: 7.5rem;
  }

  ${up('tablet-landscape')} {
    clip: rect(0px, 150px, 150px, 75px);
    width: 9.375rem;
    height: 9.375rem;
  }
`;

export const Fill = styled.div<PercentageProps>`
  width: 5.625rem;
  height: 5.625rem;
  position: absolute;
  border-radius: 50%;
  clip: rect(0px, 45px, 90px, 0px);
  background-color: ${({ theme }) => theme.colors.navy};
  
  ${({inView}) => inView ? animation : ''};

  ${up('phone')} {
    clip: rect(0px, 60px, 120px, 0px);
    width: 7.5rem;
    height: 7.5rem;
  }

  ${up('tablet-landscape')} {
    clip: rect(0px, 75px, 150px, 0px);
    width: 9.375rem;
    height: 9.375rem;
  }
`;

export const InnerCircle = styled.div`
  width: 5.568rem;
  height: 5.568rem;
  border-radius: 50%;
  background: ${({ theme }) => theme.colors.offWhite};
  position: absolute;
  top: 0.0375rem;
  left: 0.0375rem;

  ${up('phone')} {
    top: 0.05rem;
    left: 0.05rem;
    width: 7.4rem;
    height: 7.4rem;
  }

  ${up('tablet-landscape')} {
    top: 0.0625rem;
    left: 0.0625rem;
    width: 9.25rem;
    height: 9.25rem;
  }
`;

export const RadialGradient = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  left: 50%;
  top: 50%;
  width: 8.25rem;
  height: 8.25rem;
  background: radial-gradient(
    rgba(223, 191, 138, 0.49) 35.4%,
    rgba(223, 191, 138, 0.4) 45.16%,
    rgba(192, 147, 95, 0.2) 55%,
    rgba(192, 147, 95, 0) 70%);

  ${up('phone')} {
    width: 11rem;
    height: 11rem;
  }

  ${up('tablet-landscape')} {
    width: 13.75rem;
    height: 13.75rem;
  }
`;

export const RadialPulse = styled.div<RadialGradientProps>`
  position: absolute;
  border-radius: 50%;
  left: 0.75rem;
  top: 0.75rem;
  width: 4.2em;
  height: 4.2rem;

  background: radial-gradient(
    rgba(143, 106, 74, 0.52) 0,
    rgba(223, 191, 138, 0.49) 35.4%,
    rgba(223, 191, 138, 0.1) 45.16%,
    rgba(223, 191, 138, 0.0) 50%);
  
  animation-name: stretch;
  animation-duration: 1.0s;
  animation-timing-function: ease-out;
  animation-direction: alternate;
  animation-iteration-count: infinite;
  animation-play-state: running;
  animation-delay: ${({ delay }) => `${delay}ms`};

  ${up('phone')} {
    left: 1rem;
    top: 1rem;
    width: 5.6rem;
    height: 5.6rem;
  }

  ${up('tablet-landscape')} {
    left: 1.25rem;
    top: 1.25rem;
    width: 7rem;
    height: 7rem;
  }

  @keyframes stretch {
    0% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1.7);
    }
  }
`;

export const InnerContainer = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  border-radius: 50%;
  left: 50%;
  top: 50%;

  width: 7.125rem;
  height: 7.125rem;

  border: 1px dashed ${({ theme }) => theme.colors.navy};

  ${up('phone')} {
    width: 9.5rem;
    height: 9.5rem;
  }

  ${up('tablet-landscape')} {
    width: 11.875rem;
    height: 11.875rem;
  }
`;

export const CirclePointer = styled.div<PercentageProps>`
  position: absolute;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.colors.navy};
  top: -0.375rem;
  left: -0.375rem;
  width: 6.375rem;
  height: 6.375rem;
  clip-path: circle(1.7% at 50% 6%);

  ${({ inView }) => inView ? animation : ''};


  ${up('phone')} {
    top: -0.5rem;
    left: -0.5rem;
    width: 8.5rem;
    height: 8.5rem;
  }

  ${up('tablet-landscape')} {
    top: -0.625rem;
    left: -0.625rem;
    width: 10.625rem;
    height: 10.625rem;
  }

`;