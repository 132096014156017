import React, { SVGProps } from 'react';
import styled from 'styled-components';

const SVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="17"
    viewBox="0 0 19 17"
    fill="none"
    stroke="#001831"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M17.9688 8.48438L0.998187 8.48437" strokeLinecap="round"/>
  </svg>
);



const SvgLogo = styled(SVG);

export default SvgLogo`
  display: ${({ display = 'inline-block' }) => display};
`;
