import styled from 'styled-components';
import { up } from 'styled-breakpoints';


export const Container = styled.section`
  padding: 1rem;
  max-width: 1440px;
  margin: auto;

  ${up('tablet-portrait')} {
    padding: 3.875rem 6.735rem;
  }

  ${up('laptop')} {
    padding: 3.875rem 9.735rem;
  }
`;

export const SubContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  column-gap: 1rem;
  width: 100%;

  ${up('tablet-portrait')} {
    column-gap: 1.5rem;
  }
`;

export const ScrollContainer = styled.div`
  min-width: 100%;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ScrollSubContainer = styled.div`
  display: inline-block;
  min-width: 850px;
`;

export const Card = styled.div`
  width: 100%;
  max-width: 22.5rem;
  display: flex;
  flex-direction: column;
`;


export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${up('tablet-portrait')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;