import React, { FC } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import RichText from '../../components/RichText';
import Spacer from '../../components/Spacer';
import Link from '../../components/Link';
import BodyText from '../../components/BodyText';

const Container = styled.section`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;

  ${up('phone')} {
    padding: 0 1.5rem;
  }

  ${up('tablet-portrait')} {
    align-items: center;
  }
`;

const Description = styled.div`
  margin: auto;
`;

const Button = styled.link`
  padding: 15px;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.navy};
  display: flex;
  justify-content: center;

  ${up('tablet-portrait')} {
    max-width: 300px;
  }
`;

const SingleColumnText: FC<{ data: any }> = ({ data: { primary } }) => {
  const { title, description, backgroundColor, button_copy, button_link, other_link } = primary;
  const isTabletUp = useBreakpoint(up('tablet-portrait'));

  const textColor = backgroundColor === 'navy' ? 'white' : 'navy';
  return (
    <Container>
      <Spacer spacing="md4" />
      <RichText
        document={title.richText}
        textColor={textColor}
        textSize={[ null, "f9", "f13", "f15", "f16"]}
        alignment={isTabletUp ? 'center' : "left"}
      />
      <Spacer spacing={[null, "md2", "lg1"]} />
      <Description>
        <RichText
          document={description.richText}
          textColor={textColor}
          alignment={isTabletUp ? "center" : "left"}
          maxWidth={isTabletUp ? "600px" : "100%"}
          marginBottom="md1"
        />
      </Description>
      <Spacer spacing="lg1" />
      { button_link && (
        <>
          <Button as={Link} url={button_link}>
            <BodyText textColor="white">
              {button_copy}
            </BodyText>
          </Button>
          <Spacer spacing="md1" />
        </>
      )}
      {other_link && (
        <>
          <RichText
            document={other_link.richText}
          />
          <Spacer spacing="md4" />
        </>
      )}
    </Container>
  );
};

export default SingleColumnText;
