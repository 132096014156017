import React, { FC } from 'react';
import styled from 'styled-components';
import { CustomInterpolation, generateResponsiveProps, SpacingProp, ThemeObject } from '../../utils/responsive-props';
import { BaseProps, SpacingKeys, Direction, DirectionTransientProps, SpacingTransientProps } from '../../system';

type Spacing = NonNullable<SpacingKeys>;

export interface SpacerProps extends Pick<BaseProps, 'className' | 'dataId' | 'id'> {
  direction?: Extract<Direction, 'vertical' | 'horizontal'>;
  spacing?: SpacingProp;
}

export type BaseSpacerProps = SpacingTransientProps & DirectionTransientProps<'vertical' | 'horizontal'>;

export const BaseSpacer = styled.div<BaseSpacerProps>`
  width: 100%;
  height: 100%;
  ${({ $direction = 'horizontal', $spacing = 'md1', theme: { spacing: sp } }): string => {
    if ($direction === 'vertical') return `min-height: ${sp[$spacing as Spacing]};`;
    return `min-width: ${sp[$spacing as Spacing]};`;
  }}

  ${({ $spacing = 'md1', $direction = 'horizontal', theme: { spacing: sp } }): CustomInterpolation => {
    if ($direction === 'vertical') {
      return generateResponsiveProps<SpacingProp>({
        cssProperty: 'width',
        prop: $spacing,
        themeObject: sp as ThemeObject,
      });
    }
    return generateResponsiveProps<SpacingProp>({
      cssProperty: 'height',
      prop: $spacing,
      themeObject: sp as ThemeObject,
    });
  }};
`;

const Spacer: FC<SpacerProps> = ({ dataId, direction = 'horizontal', className, id, spacing = 'md1' }) => (
  <BaseSpacer className={className} data-id={dataId} $direction={direction} id={id} $spacing={spacing} />
);

export default Spacer;
