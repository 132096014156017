import { Breakpoints } from '../../system/system-types';

const breakpoints: Breakpoints = {
  'small-phone': '1px',
  phone: '375px',
  'tablet-portrait': '700px',
  'tablet-landscape': '960px',
  laptop: '1280px',
  desktop: '1920px',
};

export default breakpoints;
