import React, { FC } from 'react';
import AboutSection from '../../sections/AboutSection';
import ProductCarouselSection from '../../sections/ProductCarouselSection';
import ScienceSection from '../../sections/ScienceSection';
import EditorialReviewSection from '../../sections/EditorialReviewSection';
import IngredientsSection from '../../sections/IngredientsSection';
import LabTestedResuls from '../../sections/LabTestedResults';
import NarrowHeader from '../../sections/NarrowHeader';
import DoubleColumnText from '../../sections/DoubleColumnText';
import RecommendedProducts from '../../sections/RecommendedProducts';
import MeetTheFounder from '../../sections/MeetTheFounder';
import FancyBlock from '../../sections/FancyBlock';
import ImageText from '../../sections/ImageText';
import BasicRichText from '../../sections/BasicRichText';
import BigBanner from '../../sections/BigBanner';
import SingleColumnText from '../../sections/SingleColumnText';
import CodeSnippet from '../../sections/CodeSnippet';
import GiveawayForm from '../GiveawayForm';
import CollectionsSection from '../../sections/CollectionsSection';
import FullScreenVideo from '../../sections/FullScreenVideo';


interface Slice {
  slice_type: string;
  primary: Record<any, any>;
  items?: any[];
}

interface SlicesProps {
  slices: Slice[];
}

export interface Data {
  data: Slice;
}

const SlicesZone: FC<SlicesProps> = ({ slices }) => {
  if (!slices) return null;
  const sliceComponentMap: Record<string, FC<Data>> = {
    about_section: AboutSection,
    product_carousel: ProductCarouselSection,
    science_section: ScienceSection,
    editor_reviews: EditorialReviewSection,
    ingredients_section: IngredientsSection,
    lab_tested_results: LabTestedResuls,
    narrow_image_header: NarrowHeader,
    double_text_column: DoubleColumnText,
    recommended_products: RecommendedProducts,
    meet_the_founder: MeetTheFounder,
    fancy_block: FancyBlock,
    image___text: ImageText,
    basic_rich_text: BasicRichText,
    big_banner: BigBanner,
    single_column_text: SingleColumnText,
    code_snippet: CodeSnippet,
    giveaway_form: GiveawayForm,
    collections: CollectionsSection,
    fullscreen_video: FullScreenVideo,

  };

  const sliceZoneContent = slices.map((slice, index) => {
    const SliceComponent = sliceComponentMap[slice.slice_type];
    if (SliceComponent) {
      return <SliceComponent data={slice} key={`${slice.slice_type}_${index}`} />;
    }
    return null;
  });

  return (<>{sliceZoneContent}</>)
};

export default SlicesZone;