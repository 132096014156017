import { createTheme } from 'styled-breakpoints';
import { Theme } from '../system/theme-types';
import breakpoints from './values/breakpoints';
import colors from './values/colors';
import fonts from './values/fonts';
import fontSizes from './values/font-sizes';
import spacing from './values/spacing';
import transitions from './values/transitions';

const styledBreakpoints = createTheme({
  ...breakpoints,
})

const theme: Theme = {
  ...styledBreakpoints,
  breakpoints: {
    ...breakpoints,
  },
  colors: {
    ...colors,
  },
  fonts: {
    ...fonts,
  },
  fontSizes: {
    ...fontSizes,
  },
  spacing: {
    ...spacing,
  },
  transitions: {
    ...transitions,
  },
};

export default theme;
