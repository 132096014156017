import React, { FC } from 'react';
import styled from 'styled-components';
import Slider from "react-slick";
import RichText from '../RichText';

const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.navy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 5px;
`;

const SlideContainer = styled.div`
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  align-items: center;

  a {
    color:  ${({ theme }) => theme.colors.white};
    text-decoration: underline;
  }
`;


const AnnouncementBar: FC<{ content: any[] }> = ({ content }) => {

  return (
    <Container id="announcement-bar">
      {content.length > 1 ? (
        <Slider
          dots={false}
          infinite={true}
          vertical={true}
          autoplay={true}
          speed={500}
          autoplaySpeed={2500}
          slidesToShow={1}
          slidesToScroll={1}
          arrows={false}
          pauseOnHover={true}
        >
          {content.map((item) => (
            <SlideContainer>
            <RichText document={[item]} textColor='white' alignment="center" height="max-height" textSize={[null ,'f1', 'f2']} />
            </SlideContainer>
          ))}
        </Slider>
      ) : (
        <RichText document={content} textColor='white' alignment="center" />
      )}

    </Container>
  );
};

export default AnnouncementBar;
