import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Container = styled.section`
  width: 100%;
  margin: auto;
  background-color: ${({ theme }) => theme.colors.navy};
`;

export const SubContainer = styled.div`
  max-width: 1440px;
  margin: auto;
  padding: 2rem 1rem;

  ${up('tablet-portrait')} {
    padding: 3.875rem 6.735rem;
  }

  ${up('laptop')} {
    padding: 3.875rem 9.735rem;
  }
`;

export const IngredientHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;


  ${up('tablet-portrait')} {
    flex-direction: row;
    align-items: center;
  }
`;

export const Button = styled.button`
  width: 100%;
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  margin-bottom: 1rem;

  ${up('tablet-portrait')} {
    width: fit-content;
    background-color: transparent;
  }
`;

export const Grid = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  row-gap: 30px;

  @media screen and (min-width: 1050px) {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
  }
`;

export const Card = styled.div`
  border: 1px solid white;
  width: 100%;
  padding: 1.875rem;


  ${up('tablet-landscape')} {
    padding: 1rem;
  }
`;

export const ImageContainer = styled.div`
  width: 91px;
  height: 95px;
`;

export const ImageMetaContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const ListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 440px;

  ${up('tablet-portrait')} {
    flex-direction: row;
  }
`;


export const List = styled.div<{ image?: string }>`
  ul {
    padding-bottom: 1rem;
  }

  ul li {
    display: flex;
    align-items: center;
  }

  ul li:before {
    content: url(${({ image }) => image});
    padding-right: 7px;
    margin-top: 4px;
  }
`;
