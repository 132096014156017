import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 1rem 1rem 3rem;

  ${up('tablet-portrait')} {
    padding: 1rem 6.75rem 3.875rem;
  }

  ${up('laptop')} {
    padding: 1rem 9.735rem 3.875rem;
  };
`;

export const SubContainer = styled.div`
  display: flex;
  width: 955px;
  margin: auto;
  justify-content: space-between;

  ${up('tablet-portrait')} {
    width: 1128px;
  }
`;

export const CardContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 310px;

  ${up('tablet-portrait')} {
    max-width: 360px;
  }
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const PriceContainer = styled.div`
  display: flex;
`;

export const DiscountSticker = styled.div`
  position: absolute;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.navy};
  height: 114px;
  width: 114px;
  border-radius: 50%;
  padding: 1.625rem 0;
  top: 10px;
  right: -10px;
`;
   