import React, { SVGProps } from 'react';
import styled from 'styled-components';

const SVG = (props: SVGProps<SVGSVGElement>) => (
<svg
  width="31"
  height="8"
  viewBox="0 0 31 8"
  fill="#001831"
  xmlns="http://www.w3.org/2000/svg"
  {...props}
>
  <path d="M0.646444 3.64644C0.451183 3.84171 0.451183 4.15829 0.646444 4.35355L3.82842 7.53553C4.02369 7.73079 4.34027 7.73079 4.53553 7.53553C4.73079 7.34027 4.73079 7.02369 4.53553 6.82842L1.70711 4L4.53553 1.17157C4.7308 0.976308 4.7308 0.659726 4.53553 0.464464C4.34027 0.269202 4.02369 0.269202 3.82842 0.464464L0.646444 3.64644ZM31 3.5L0.999998 3.5L0.999998 4.5L31 4.5L31 3.5Z" />
</svg>
);



const SvgLogo = styled(SVG);

export default SvgLogo`
  display: ${({ display = 'inline-block' }) => display};
`;
