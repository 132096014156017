import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import RichText from '../../components/RichText';
import Spacer from '../../components/Spacer';

const ImgContainer = styled.div<{ image: string }>`
  height: 500px;
  background-image: url("${({ image }) => image}");
  background-position: center;
  border-bottom-left-radius: 273px 276px;
  overflow: hidden;
  
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;

  ${up('tablet-portrait')} {
    height: 700px;
    border-bottom-left-radius: 273px 276px;
  }
`;

const Container = styled.section`
  position: relative;
  padding: 0 1.5rem 1.5rem;
  max-width: 1440px;
  margin: auto;
`;

const ContentContainer = styled.div`
  width: 100%;

  ${up('tablet-portrait')} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;

const BigBanner: FC<{ data: any }> = ({ data: { primary }}) => {
  const isTabletUp = useBreakpoint(up('tablet-portrait'));
  const { desktop_background, mobile_background, title } = primary;

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.findation.com/javascripts/widgetv1.js';
    script.setAttribute('data-api-key', "5e77b95f445dd632c53b5824bf90dcb0e9ee73775c98d51280ebcbb901b2");
    script.setAttribute('width', "300px");
    script.async = true
    script.type = "text/javascript"
    script.id = "findation-widget-button"

    const container = document.getElementById('findation-button');

    container?.appendChild(script);

    return () => {
      container?.removeChild(script);
    }
  }, []);

  return (
    <Container>
      {isTabletUp ? (
        <ImgContainer image={desktop_background.url}/>
        ) : (
          <GatsbyImage image={mobile_background.gatsbyImageData} alt=""
            imgStyle={{ borderBottomLeftRadius: '31% 22%' }}
          />
        )
      }
      <ContentContainer>
        {!isTabletUp && <Spacer spacing="md3" />}
        <RichText
          document={title.richText}
          textColor={isTabletUp ? 'white': 'navy'}
          alignment={isTabletUp ? 'center': 'left'}
          textSize={[null, "f9", "f13"]}
          maxWidth="600px"
          lineHeight="1"
        />
        <Spacer spacing="md2" />
        <ButtonContainer id="findation-button" />
      </ContentContainer>
    </Container>
  )
};

export default BigBanner;
