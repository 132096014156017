import React, { FC, forwardRef, Ref } from 'react';
import { PrismicRichText } from '@prismicio/react';
import Heading from '../Heading';
import BodyText from '../BodyText';
import { BaseTextProps } from '../../system/system-types'
import { SpacingProp } from '../../utils/responsive-props';

interface RichTextProps extends BaseTextProps {
  document: any;
  ref?: Ref<HTMLParagraphElement | HTMLHeadingElement>;
  marginBottom?: SpacingProp;
}

const RichText: FC<RichTextProps> = forwardRef(({
  document,
  textSize,
  textColor = 'navy',
  fontFamily,
  alignment,
  maxWidth,
  lineHeight,
  marginBottom,
  width,
}, ref) => {
  return (
    <PrismicRichText 
      field={document}
      components={{
        heading1: ({ children }) => (
          <Heading
            textSize={textSize ? textSize : [null, null, "f8", "f10"]}
            fontFamily={fontFamily}
            textColor={textColor}
            alignment={alignment}
            maxWidth={maxWidth}
            width={width}
            ref={ref}
            lineHeight={lineHeight}
          >
            {children}
          </Heading>
        ),
        heading2: ({ children }) => (
          <Heading
            as="h2"
            textSize={textSize}
            fontFamily={fontFamily}
            textColor={textColor}
            alignment={alignment}
            maxWidth={maxWidth}
            width={width}
            ref={ref}
            lineHeight={lineHeight}
          >
            {children}
          </Heading>
        ),
        heading3: ({ children }) => (
          <Heading
            as="h3"
            textSize={textSize}
            fontFamily={fontFamily}
            textColor={textColor}
            alignment={alignment}
            maxWidth={maxWidth}
            width={width}
            ref={ref}
            lineHeight={lineHeight}
          >
            {children}
          </Heading>
        ),
        heading4: ({ children }) => (
          <Heading
            as="h4"
            textSize={textSize}
            fontFamily={fontFamily}
            textColor={textColor}
            alignment={alignment}
            maxWidth={maxWidth}
            width={width}
            ref={ref}
            lineHeight={lineHeight}
          >
            {children}
          </Heading>
        ),
        heading5: ({ children }) => (
          <Heading
            as="h5"
            textSize={textSize}
            fontFamily={fontFamily}
            textColor={textColor}
            alignment={alignment}
            maxWidth={maxWidth}
            width={width}
            ref={ref}
            lineHeight={lineHeight}
          >
            {children}
          </Heading>
        ),
        heading6: ({ children }) => (
          <Heading
            as="h6"
            textSize={textSize}
            fontFamily={fontFamily}
            textColor={textColor}
            alignment={alignment}
            maxWidth={maxWidth}
            width={width}
            ref={ref}
            lineHeight={lineHeight}
          >
            {children}
          </Heading>
        ),
        paragraph: ({ children }) => (
          <BodyText
            textSize={textSize}
            fontFamily={fontFamily}
            textColor={textColor}
            alignment={alignment}
            maxWidth={maxWidth}
            width={width}
            lineHeight={lineHeight}
            marginBottom={marginBottom}
          >
            {children}
          </BodyText>
        ),
        listItem: ({ children }) => (
          <BodyText
            as="li"
            textSize={textSize}
            fontFamily={fontFamily}
            textColor={textColor}
            alignment={alignment}
            maxWidth={maxWidth}
            width={width}
            lineHeight={lineHeight}
            marginBottom={marginBottom}
            style={{ marginLeft: '1rem' }}
          >
            {children}
          </BodyText>
        )
      }}
    />
  )
});

export default RichText;
