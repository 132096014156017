import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';



export interface Items {
  label: string;
  link: Record<any, any>;
  link_type?: string;
  url?: string;
};

export type MenuData = {
  menu_links: Items[];
  submenu_column_1: Items[];
  submenu_column_2: Items[];
  ad_image: any;
  ad_button_link: Items;
  ad_button_label: string;
}

interface NavigationProps {
  data?: MenuData;
  backgroundColor?: string;
};


export const StickyContainer = styled.nav`
  position: sticky;
  position: -webkit-sticky;
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 99;
  top: 0;
`;

const Navigation: FC<NavigationProps> = ({ data, backgroundColor="offWhite" }) => {
  const [isSubnavOpen, setIsSubnavOpen] = useState(false);
  const isTabletUp = useBreakpoint(up('tablet-portrait'));

  const handleShopClick = () => {
    setIsSubnavOpen(!isSubnavOpen);
  };

  return (
    <StickyContainer>
      {isTabletUp ? (
        <DesktopNavigation
          onShopClick={handleShopClick}
          isSubnavOpen={isSubnavOpen}
          data={data}
          backgroundColor={backgroundColor}
        />
      ) : (
        <MobileNavigation
          data={data}
          backgroundColor={backgroundColor}
        />
      )}
    </StickyContainer>
  );
}

export default Navigation;
