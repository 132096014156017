import styled from 'styled-components';
import { up } from 'styled-breakpoints';


export const Container = styled.footer`
  background-color: ${({ theme }) => theme.colors.navy};

  overflow: hidden;

  ${up('tablet-portrait')} {
    padding: 4rem 3rem;
    max-height: 36.5rem;
  }

  ${up('tablet-landscape')} {
    padding: 5rem 5rem;
    max-height: 40rem;
  }

  ${up('laptop')} {
    padding: 8rem 9.625rem;
    max-height: 49.063rem;
  }
`;

export const SubContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1440px;
  height: 100%;
`;

export const ContentContainer = styled.div`
  display: flex;
  height: 100%;
`;

export const LogoContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const SignUpContainer = styled.div`
  width: 50%;
  padding-right: 10%;
  display: flex;
  flex-direction: column;
`;

export const MenuContainer = styled.div`
  display: flex;
  width: 50%;
`;

export const Column = styled.div`
  width: 50%;
`;

export const FormContainer = styled.div`
  height: 45px;
  width: 100%;
`;