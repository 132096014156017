/**
 * Adapted from https://github.com/charlie-tango/hooks/tree/master/packages/useFocusTrap/src
 */
 import { useCallback, useEffect, useRef } from 'react';
 import { markForFocusLater, returnFocus, setupScopedFocus, teardownScopedFocus } from './helpers/focusManager';
 import { focusSelector, focusable, tabbable } from './helpers/tabbable';
 import scopeTab from './helpers/scopeTab';
 import createAriaHider from './helpers/ariaHider';
 
 export type FocusTrapOptions = {
   focusSelector?: string | HTMLElement;
   disableAriaHider?: boolean;
 };
 
 /**
  * A hook that traps focus to a DOM node. Use this with a modal to ensure the user focus doesn't leave.
  *
  * @param active {Boolean} - If true, the focus trap is turned on
  * @param options {Object} - Additional options (disableAriaHider (disables
  * aria-hidden on body) or focusSelector (initial focus item))
  * @return {HTMLElement} - A ref that can be assigned to a DOM element
  */
 function useFocusTrap(active = true, options: FocusTrapOptions = {}): (instance: HTMLElement | null) => void {
   const ref = useRef<HTMLElement | null>();
   // eslint-disable-next-line @typescript-eslint/ban-types
   const restoreAria = useRef<Function | null>(null);
 
   const setRef = useCallback(
     (node: HTMLElement | null) => {
       if (restoreAria.current) {
         restoreAria.current();
       }
       if (ref.current) {
         returnFocus();
         teardownScopedFocus();
       }
       if (active && node) {
         setupScopedFocus(node);
         markForFocusLater();
 
         const processNode = (el: HTMLElement): void => {
           // See if we should disable aria
           restoreAria.current = !options.disableAriaHider ? createAriaHider(el) : null;
 
           // Find the initial focus element
           let focusElement: HTMLElement | null = null;
           if (options.focusSelector) {
             focusElement =
               typeof options.focusSelector === 'string'
                 ? el.querySelector(options.focusSelector)
                 : options.focusSelector;
           }
 
           if (!focusElement) {
             const children = Array.from<HTMLElement>(el.querySelectorAll(focusSelector));
             focusElement =
               // Prefer tabbable elements, But fallback to any focusable element
               children.find(tabbable) ||
               // But fallback to any focusable element
               children.find(focusable) ||
               // Nothing found
               null;
 
             // If everything else fails, see if the node itself can handle focus
             if (!focusElement && focusable(el)) focusElement = el;
           }
 
           if (focusElement) {
             // Set the initial focus inside the traps
             focusElement.focus();
           } else {
             // eslint-disable-next-line no-lonely-if
             if (process.env.NODE_ENV === 'development') {
               // eslint-disable-next-line no-console
               console.warn(
                 // eslint-disable-next-line max-len
                 '[useFocusTrap]: Failed to find a focusable element after activating the focus trap. Make sure to include at an element that can recieve focus. As a fallback, you can also set "tabIndex={-1}" on the focus trap node.',
                 el,
               );
             }
           }
         };
 
         // Delay processing the HTML node by a frame. This ensures focus is assigned correctly.
         setTimeout(() => {
           if (node.ownerDocument) {
             processNode(node);
           } else if (process.env.NODE_ENV === 'development') {
             // eslint-disable-next-line no-console
             console.warn(
               // eslint-disable-next-line max-len
               '[useFocusTrap]: The focus trap is not part of the DOM yet, so it is unable to correctly set focus. Make sure to render the ref node.',
               node,
             );
           }
         });
 
         ref.current = node;
       } else {
         ref.current = null;
       }
     },
     [active, options.focusSelector, options.disableAriaHider],
   );
 
   useEffect(() => {
     if (!active) return undefined;
     const handleKeyDown = (event: KeyboardEvent): void => {
       if (event.key === 'Tab' && ref.current) {
         scopeTab(ref.current, event);
       }
     };
 
     document.addEventListener('keydown', handleKeyDown);
     return (): void => {
       document.removeEventListener('keydown', handleKeyDown);
     };
   }, [active]);
 
   return setRef;
 }
 
 export default useFocusTrap;
 