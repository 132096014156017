import React, { FC } from 'react';
import Layout from '../../components/Layout';
import CustomPage from '../../sections/CustomPage';

interface PageProps {
  pageContext: {
    data: any;
    navData: any;
  }
};

const Page: FC<PageProps> = ({ pageContext: { data, navData } }) => {
  return (
    <Layout backgroundColor={data?.background_color}>
      <CustomPage data={data} />
    </Layout>
  )
};

export default Page;

export const Head = () => (
  <link id="icon" rel="icon" type="image/png" href="/favicon/favicon.ico" />
);
