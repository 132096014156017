import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const ButtonContainer = styled.div`
  position: relative;
`;


export const Button = styled.button`
`;

export const Blur = styled.div`
  position: absolute;
  height: 100vh;
  inset: 0;
  background-color: rgba(245, 243, 238, 0.3);
  backdrop-filter: blur(7px);
  z-index: 100;
`;

export const CheckoutContainer = styled.section`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  max-width: 31.875rem;
  height: 100vh;
  border-left: solid 1px ${({ theme }) => theme.colors.navy};
  background-color: ${({ theme }) => theme.colors.offWhite};
  z-index: 101;
`;

export const SubContainer = styled.div`
  position: relative;
  height: 100vh;
`;

export const CheckoutHeader = styled.div`
  padding: 1.125rem .5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid 1px ${({ theme }) => theme.colors.navy};

  ${up('phone')} {
    padding: 1.125rem 1.5rem;
  }
`;

export const CopyContainer = styled.div`
  padding: 5.1875rem 2rem;
`;


const getCurrentYPosition = () => {
  // Firefox, Chrome, Opera, Safari
  if (self.pageYOffset) return self.pageYOffset;
  // Internet Explorer 6 - standards mode
  if (document.documentElement && document.documentElement.scrollTop)
    return document.documentElement.scrollTop;
  // Internet Explorer 6, 7 and 8
  if (document.body.scrollTop) return document.body.scrollTop;
  return 0;
};

export const CheckoutButton = styled.button`
  position: absolute;
  left: 0;
  top: ${() => {
    const announcementBar = document.querySelector('#announcement-bar');
    const announcementBarHeight = announcementBar?.clientHeight ?? 0;
    const currentY = getCurrentYPosition()

    let offSet = 0;

    if (currentY < announcementBarHeight) {
      offSet = announcementBarHeight - currentY;
    }
    return `calc(100dvh - (67px + ${offSet}px))`}
  };
  background-color: ${({ theme }) => theme.colors.navy};
  height: 67px;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  white-space: pre;
  cursor: pointer;
`;

export const QuantityIndicator = styled.div`
  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.3);
    }
    100% {
      transform: scale(1);
    }
  }

  position: absolute;
  top: -7px;
  right: -15px;
  background-color: ${({ theme }) => theme.colors.gold};
  width: 22px;
  height: 22px;
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;
  animation: pulse 500ms ease-in-out;

`;

export const List = styled.ul`
  list-style: none;
  overflow-y: scroll;
  height: calc(100% - 67px);
  padding-bottom: 100px;
`;