import React, { FC } from 'react';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import DesktopFooter from './DesktopFooter';
import MobileFooter from './MobileFooter';

type Links = {
  name: string;
  link: string;
}

export interface FooterProps {
  exploreList: Links[];
  connectList: Links[];
  resourcesList: Links[];
  year: number;
  formDescription: string;
}

const Footer: FC = () => {
  const isTabletUp = useBreakpoint(up('tablet-portrait'));

  const column1 = [
    {
      name: 'Shop',
      link: '/collections',
    },
    { 
      name: 'About',
      link: '/about-us',
    },
    {
      name: 'Science',
      link: '/the-science',
    },
    {
      name: 'Find Your Shade',
      link: '/find-your-shade'
    },
    {
      name:'Insider Club',
      link: '/insider-club/'
    },
  ];

  const column2 = [
    {
      name: 'Facebook',
      link: 'https://www.facebook.com/orcecosmetics/'
    },
    {
      name: 'Instagram',
      link: 'https://www.instagram.com/orcecosmetics',
    },
    {
      name: 'TikTok',
      link: 'https://www.tiktok.com/@orce.cosmetics'
    }
  ];
  const column3 = [
    {
      name: 'Contact',
      link: 'mailto:info@orcecosmetics.com',
    },
    {
      name: 'FAQ',
      link: '/faq',
    },
    {
      name: 'Shipping & Return Policy',
      link: '/shipping-return-policy',
    },
    {
      name: 'Privacy Policy',
      link: '/privacy-policy',
    },
    {
      name: 'Terms of Service',
      link: '/terms-of-service',
    },
  ];

  const formDescription = "Your community is waiting for you."
  const year = new Date().getFullYear();

  return (isTabletUp ?
    <DesktopFooter
      exploreList={column1}
      connectList={column2}
      resourcesList={column3}
      year={year}
      formDescription={formDescription}
    /> :
    <MobileFooter
      exploreList={column1}
      connectList={column2}
      resourcesList={column3}
      year={year}
      formDescription={formDescription}
    /> )
};

export default Footer;
