import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 1440px;
  margin: auto;
  padding-bottom: 3rem;

  padding: 0 1rem 3rem;

  ${up('phone')} {
    padding: 0 1.5rem 3rem;
  }

  ${up('tablet-portrait')} {
    padding: 1.5rem 3.75rem 2rem;
  }

  ${up('laptop')} {
    padding: 1.5rem 9.735rem 2rem;
  }
`;

export const SubContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const ImageContainer = styled.div`
  max-width: 100%;
  overflow: hidden;

  ${up('tablet-portrait')} {
    max-width: 600px;
  }

`;

export const TitleContainer = styled.div`
  margin-top: 1.5rem;
  width: 100%;

  ${up('tablet-portrait')} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
  }
`;
export const DescriptionContainer = styled.div`

  ${up('tablet-portrait')} {
    padding: 1rem  2.5rem;
  }
`;