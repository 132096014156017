import React, { FC, useEffect } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import ScreenReaderOnly from '../../../ScreenReaderOnly';
import BodyText from '../../../BodyText';
import Link from '../../../Link';
import Spacer from '../../../Spacer';
import useFocusTrap from '../../../../hooks/useFocusTrap';
import { Items } from '../../Navigation';
import * as S from './Subnav-styled';

interface Link {
  url?: string;
  link_type?: string;
};


interface SubnavProps {
  isOpen: boolean;
  column1?: Items[];
  column2?: Items[];
  adImage?: any;
  adButtonLabel?: string;
  adButtonLink?: Link;
  onClick?: () => void;
  backgroundColor?: string;
}


const Subnav: FC<SubnavProps> = ({
  column1,
  column2,
  isOpen,
  adImage,
  adButtonLabel,
  adButtonLink,
  onClick,
  backgroundColor = 'offWhite'
}) => {
  const subNavRef = useFocusTrap(isOpen);
  const textColor = backgroundColor === 'navy' ? 'offWhite' : 'navy';

  useEffect(() => {
    if (!isOpen) return;
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    if (body && html) {
      html.style.overflow = 'hidden';
    }
    return () => {
      if (body && html) {
        html.style.overflow = 'visible';
      }
    }
  }, [isOpen]);

  return (
    <>
      <S.SubMenuContainer ref={subNavRef} backgroundColor={backgroundColor}>
        <S.Columm>
          <Spacer spacing="md2" />
          <BodyText textColor={textColor}>
            SHOP
          </BodyText>
          <Spacer spacing="sm2" />
          <ul>
            {column1?.map((item) => (
              <S.NavItem key={item?.label}>
                <Link url={item.link?.url} type={item.link?.link_type} >
                  <BodyText textColor={textColor}>
                    {item?.label}
                  </BodyText>
                </Link>
                <Spacer spacing="sm1" />
              </S.NavItem>
            ))}
          </ul>
          <Spacer spacing="md2" />
          <Link url="/collections" type="document" >
            <BodyText css={`text-decoration: underline`} textColor={textColor}>
              SHOP ALL
            </BodyText>
          </Link>
        </S.Columm>
        <S.Columm>
          <Spacer spacing="md2" />
          <BodyText textColor={textColor}>
            SETS
          </BodyText>
          <Spacer spacing="sm2" />
          <ul>
            {column2?.map((item) => (
              <S.NavItem key={item?.label}>
                <Link url={item.link?.url} type={item.link?.link_type} >
                  <BodyText textColor={textColor}>
                    {item?.label}
                  </BodyText>
                </Link>
                <Spacer spacing="sm1" />
              </S.NavItem>
            ))}
          </ul>
        </S.Columm>
        <S.AdContainer>
            <S.ImageContainer>
              <GatsbyImage image={adImage} alt="" aria-hidden="true" />
            </S.ImageContainer>
            <S.Button>
              <Link url={adButtonLink?.url ?? ''} type={adButtonLink?.link_type} >
                <BodyText
                  alignment="center"
                  textColor="navy"
                  css={`
                    padding: 1.063rem 2rem;
                    transition: background-color 0.3s;
                    &:hover,
                    :focus {
                      color: #F5F3EE;
                    }
                  `}
                >
                  {adButtonLabel}
                </BodyText>
              </Link>
            </S.Button>
        </S.AdContainer>
        <ScreenReaderOnly>
          <button type="button" onClick={onClick}>
            close subnavigation
          </button>
        </ScreenReaderOnly>
      </S.SubMenuContainer>
      <S.Blur onClick={onClick} aria-hidden="true" />
    </>
  )
};

export default Subnav;