import React, { FC } from 'react';
import Slices from '../../components/Slices';
import * as S from './CustomPage-styled';

interface CustomPageProps {
  data: any;
};

const CustomPage: FC<CustomPageProps> = ({ data }) => {
  const { background_color, body } = data ?? {};
  const addData = () => {
    body.forEach((item: any) => {
      if (item?.primary?.slice_type !== 'narrow_image_header') {
        if (item?.primary) item.primary['backgroundColor'] = background_color;
        else {
          item['backgroundColor'] = background_color;
        }
      }
    })
  };

  addData();

  return (
    <S.Container backgroundColor={background_color}>
      <S.SubContainer>
        <Slices slices={body} />
      </S.SubContainer>
    </S.Container>
  )
};

export default CustomPage;
