import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { FadeinUp } from '../../styles/FadeinUp';

interface ContainerProps {
  inView: boolean;
};

export const Container = styled.section`
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1440px;

  ${up('tablet-portrait')} {
    padding: 0 6.735rem;
  }

  ${up('tablet-landscape')} {
    padding: 0 9.735rem;
  }
`;

export const SubContainer = styled.div<ContainerProps>`
  ${FadeinUp};
`;

export const ResultContainer = styled.div`
  border: 0.0625rem solid ${({ theme }) => theme.colors.navy};
  display: flex;
  justify-content: space-around;

  ${up('tablet-portrait')} {
    flex-direction: row;
    flex-wrap: wrap;
    padding: 0 2rem;
  }
`;


export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ContentContainer = styled.section<ContainerProps>`
  display: flex;
  flex-direction: column;
  border: 0.0625rem solid ${({ theme }) => theme.colors.navy};
  padding: 3.75rem 1.9375rem;

  ${FadeinUp};

  ${up('tablet-landscape')} {
    padding: 3.75rem 2.9375rem;
  }

  ${up('laptop')} {
    padding: 3.75rem 4.9375rem;
  }
`;

export const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns:  calc(50% - 110px) 120px calc(50% - 110px);
  grid-column-gap: 50px;
`;

export const ContentColumn = styled.div<{alignment?: string}>`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  text-align: ${({ alignment }) => alignment ?? 'left'};
`;

export const ImageContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const ImageWrapper = styled.div`
  max-height: 400px;
`;

export const MobileMenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.blue};
`;

export const SubNavContainer = styled.div<{isOpen?: boolean}>`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  max-height: ${({ isOpen }) => isOpen ? '100rem' : '0'};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;

  ${up('phone')} {
    padding: 0 1.5rem;
  }
`;

export const TitleContainer = styled.div`
  padding: 0 1rem;

  ${up('phone')} {
    padding: 0 1.5rem;
  }

  ${up('tablet-portrait')} {
    padding: 0;
  }
`;