import React, { SVGProps } from 'react';
import styled from 'styled-components';

const SVG = (props: SVGProps<SVGSVGElement>) => (
<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<rect width="24" height="24" rx="12" fill-opacity="0.8" fill={props.fill ?? "#F5F3EE"} />
<path d="M6.40002 18.3079L5.69202 17.5999L11.292 11.9999L5.69202 6.39989L6.40002 5.69189L12 11.2919L17.6 5.69189L18.308 6.39989L12.708 11.9999L18.308 17.5999L17.6 18.3079L12 12.7079L6.40002 18.3079Z" fill="#001834"/>
</svg>


);



const SvgLogo = styled(SVG);

export default SvgLogo`
  display: ${({ display = 'inline-block' }) => display};
`;
