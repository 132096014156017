import { Fonts } from '../../system';

const fonts: Fonts = {
  helvetica: `Helvetica Neue, Helvetica, -apple-system, BlinkMacSystemFont,` + `helvetica, ubuntu, roboto, noto, 'Segoe UI', Arial, sans-serif`,
  parnaso:
    `Parnaso, -apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'Helvetica Neue', ` +
    `helvetica, ubuntu, roboto, noto, 'Segoe UI', Arial, sans-serif`,
  futuraDemi:
    `"FuturaPT-Demi", -apple-system, BlinkMacSystemFont, 'avenir next', avenir, 'Helvetica Neue', ` +
    `helvetica, ubuntu, roboto, noto, 'Segoe UI', Arial, sans-serif`,
  sourceSans:
    'SourceSansPro-Regular,-apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif',
  sourceSansIt:
    'SourceSansPro-It,-apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif',
  sourceSansBold:
    'SourceSansPro-Bold,-apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif',
  canonatia:
    'Canonatia,-apple-system,BlinkMacSystemFont,avenir next,avenir,helvetica neue,helvetica,ubuntu,roboto,noto,segoe ui,arial,sans-serif',
};

export default fonts;
