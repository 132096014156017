import React, { FC, ReactNode } from 'react';
import { ThemeProvider } from 'styled-components';
import { Script, useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import theme from '../../theme';
import Navigation from '../Navigation';
import Footer from '../Footer';
import PopUp from '../PopUp';
import AnnouncementBar from '../AnnouncementBar';
import CSSReset from '../../styles/CSSReset';

interface LayoutProps {
  children: ReactNode;
  backgroundColor?: string;
}

const Main = styled.main`
  scrollbar-gutter: stable both-edges;
`;

const navData = graphql`
  query {
    prismicNavigation {
      ...NavQuery
    }
  }
`;

const Layout: FC<LayoutProps> = ({ children, backgroundColor="offWhite" }) => {
  const { prismicNavigation } = useStaticQuery(navData);

  const hasAnnouncement = prismicNavigation?.data?.announcement?.richText?.[0]?.text?.length !== 0

  return (
    <ThemeProvider theme={theme} >
      <CSSReset />
      <PopUp />
      <div>
        {hasAnnouncement && (
          <AnnouncementBar content={prismicNavigation?.data?.announcement?.richText} />
        )}
        <Navigation data={prismicNavigation?.data} backgroundColor={backgroundColor} />
        <Main>
          {children}
        </Main>
      </div>
      <Footer />
      <Script id="prismic-previews" src="https://static.cdn.prismic.io/prismic.js?new=true&repo=us-web-static" />
      <Script id="shop-my" src="https://static.myshlf.us/Affiliates/sms_aff_clicktrack.js" async />
      <Script id="tik-tok" async>{`
        !function (w, d, t) {
          w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++)ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{},ttq._partner=ttq._partner||'Shopify';var o=document.createElement("script");o.type="text/javascript",o.async=!0,o.src=i+"?sdkid="+e+"&lib="+t;var a=document.getElementsByTagName("script")[0];a.parentNode.insertBefore(o,a)};
          ttq.load('CHRST3BC77U65NMA4T30');
          ttq.page();
        }(window, document, 'ttq');
      `}
      </Script>
      <Script id="klaviyo" src="https://static.klaviyo.com/onsite/js/klaviyo.js?company_id=HM6FU2" />
      <Script id="route" src="https://cdn.routeapp.io/route-widget/stable/route-widget-stable.min.js" />
    </ThemeProvider>
  );
}

export default Layout;