import { Spacing } from '../../system';

const spacing: Spacing = {
  // no spacing
  sp0: '0',
  // small
  sm1: '0.125rem' /* 2px */,
  sm2: '0.25rem' /* 4px */,
  sm3: '0.5rem' /* 8px */,
  sm4: '0.75rem' /* 12px */,
  // medium
  md1: '1rem' /* 16px */,
  md2: '1.25rem' /* 20px */,
  md3: '1.5rem' /* 24px */,
  md4: '2rem' /* 32px */,
  // large
  lg1: '2.5rem' /* 40px */,
  lg2: '3rem' /* 48px */,
  lg3: '4rem' /* 64px */,
  lg4: '5rem' /* 80px */,
  // xlarge
  xlg1: '6rem' /* 96px */,
  xlg2: '8rem' /* 128px */,
  xlg3: '12rem' /* 192px */,
  xlg4: '16rem' /* 256px */,
};

export default spacing;
