import React, { SVGProps } from 'react';
import styled from 'styled-components';

const SVG = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    xmlns="http://www.w3.org/2000/svg"
    fill="#001831"
    stroke="#001831"
    {...props}
  >
    <path d="M9.48438 1V17.9706"  strokeLinecap="round"/>
    <path d="M17.9688 9.48438L0.998187 9.48437" strokeLinecap="round"/>
  </svg>
);



const SvgLogo = styled(SVG);

export default SvgLogo`
  display: ${({ display = 'inline-block' }) => display};
`;
