import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Container as SectionContainer } from '../../styles/SectionContainer';

interface ContainerProps {
  inView?: boolean;
}


export const Container = styled.section<ContainerProps>`
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  margin: auto;
  ${SectionContainer};

  ${up('tablet-portrait')} {
    flex-direction: row;
  }
`;

export const MetaSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 1rem;
  width: 100%;

  ${up('phone')} {
    padding: 0 1.5rem;
  }

  ${up('tablet-portrait')} {
    padding: 0 6% 0 0;
    width: 50%;
  }

  ${up('tablet-portrait')} {
    padding: 0 8% 0 0;
  }
`;

export const ImageContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  ${up('tablet-portrait')} {
    padding-left: 1rem;
    width: 50%;
  }
`;

export const MarqueeContainer = styled.div`
  position: absolute;
  width: 100%;
  height: 2rem;
  background-color: ${({ theme }) => theme.colors.gold};
  bottom: 0;
  display: flex;
  align-items: center;

  ${up('tablet-portrait')} {
    width: calc(100% - 1rem);
  }

`;