import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Container = styled.div`
  padding: 1rem;
  border-bottom: solid 1px ${({ theme }) => theme.colors.navy};
  display: flex;

  ${up('tablet-portrait')} {
    padding: 1.875rem 1.5rem;
  }

`;

export const ImageContainer = styled.div`
  width: 114px;
  height: 150px;
  background-color: grey;
`;

export const MetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: calc(100% - 138px);
`;

export const Flex = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${up('tablet-portrait')} {
    flex-direction: row;
  }
`;

export const ProductInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
`;


export const QuantityContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.navy};
  width: 111px;
  height: 37px;
  display: flex;
  align-items: center;
`;

export const DecreaseButton = styled.button`
  border-right: 1px solid ${({ theme }) => theme.colors.navy};
  height: 37px;
  width: 37px;
`;

export const IncreaseButton = styled.button`
  border-left: 1px solid ${({ theme }) => theme.colors.navy};
  height: 37px;
  width: 37px;
`;

