import styled from 'styled-components';
import { Focus } from '../../../utils/css'

interface ButtonProps {
  backgroundColor?: string;
  borderColor?: string;
}

interface SideNavProps {
  isOpen: boolean;
}

export const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: row;
  height: 3.75rem;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme, backgroundColor }): string => backgroundColor === 'offWhite' ? theme.colors.offWhite : theme.colors.navy};
`;

export const CartContainer = styled.div`
  padding-right: 1.5rem;
  padding-left: 1.5rem;
`

export const Button = styled.button<ButtonProps>`
  padding: 1rem 1rem 1rem 1.5rem;
  border-bottom: solid 1px;
  border-bottom-color: ${({ borderColor, theme }) => theme.colors[borderColor ?? 'navy']};
  background-color:
    ${({ theme, backgroundColor }): string => backgroundColor ? (theme.colors[backgroundColor] ?? backgroundColor) : ''};
  ${Focus};

  &:active,
  :focus {
    outline: 0 !important;
    -moz-outline-style: none;
    box-shadow: none;
    &:active, :focus {
      border-bottom: solid 1px ${({ theme }) => theme.colors.navy};
    }
  }
`;


export const SideNav = styled.div<SideNavProps>`
  position: absolute;
  width: 100%;
  left: ${({ isOpen }) => isOpen ? '0' : '-100%'};
  background-color: ${({ theme }): string => theme.colors.offWhite};
  border-right: solid 1px ${({ theme }): string => theme.colors.navy};
  overflow-y: auto;
  height: 100vh;

  transition: left .3s ease;
  `;


export const SideNavSubContainer = styled.div`
  display: flex;
  flex-direction: column;

`;

export const ShopNavContainer = styled.div<SideNavProps>`
  display: flex;
  flex-direction: column;
  padding: 0 1.5rem;
  max-height: ${({ isOpen }) => isOpen ? '100rem' : '0'};
  overflow: hidden;
  transition: max-height 0.3s linear 0s;

  a:last-child {
    border-bottom: solid 1px ${({ theme }) => theme.colors.navy};
    margin: 0 -1.5rem;
    padding: 1.5rem;
  }
`;