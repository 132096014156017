import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  z-index: 2;
`;

export const SubContainer = styled.div`
  position: relative;
`;

export const Button = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  border: none;
  background-color: ${({ theme }) => theme.colors.navy};
  cursor: pointer;
  transition: 0.3s;
  border-radius: 5px;
  width: 100%;

  :hover {
    background-color: ${({ theme }) => theme.colors.gold};
  }
`;

export const CloseButton = styled.button`
  position: absolute;
  top: -10px;
  left: -10px;
`;