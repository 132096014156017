import React, { FC, useState } from 'react';
import styled from 'styled-components';
import BodyText from '../BodyText';
import Spacer from '../Spacer';
import { Colors } from '../../system';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const FormContainer = styled.form<{ direction: string }>`
  display: flex;
  flex-direction: ${({ direction }) => direction};
`;

const Input = styled.input<{color: Colors | string, direction: string}>`
  -webkit-appearance: none;
  background-color: transparent;
  border: solid 1px ${({ theme, color }) => theme.colors[color]};
  border-radius: 5px;
  padding: 10px;
  color: ${({ theme, color }) => theme.colors[color]};
  font-size: ${({ theme }) => theme.fontSizes.f3};
  width: ${({ direction }) => direction === 'column' ? '300px' : '100%'};
  max-width: 300px;
  flex: 1 0 0px;


  &::placeholder {
    color: ${({ theme, color }) => theme.colors[color]};
    opacity: 0.5;
  }

  &:focus,
  :hover,
  :focus-visible,
  :active {
    box-shadow: 0 0 5px rgb(14, 148, 215);
    border-color: ${({ theme, color }) => theme.colors[color]};
    outline: none;
  }
`;

const Button = styled.button<{ buttonColor: string, borderRadius?: string }>`
  border-radius: ${({borderRadius}) => borderRadius ?? '0px'};
  padding: 8px 12px;
  background-color: ${({ buttonColor, theme }) => theme.colors[buttonColor] ?? 'transparent'};
`;

const NewsletterId = 'LiLBTq'

interface NewsLetterFormProps {
  listId?: string;
  color?: keyof Colors;
  direction?: 'row' | 'column';
  buttonColor?: string;
  placeholder?: string;
  borderRadius?: string;
}

const NewsLetterForm: FC<NewsLetterFormProps> = ({
  listId = NewsletterId,
  color = 'white',
  direction = 'row',
  buttonColor="transparent",
  placeholder="Email",
  borderRadius
}) => {
  const [statusMessage, setStatusMessage] = useState<null | string>(null);
  const [status, setStatus] = useState<'success' | 'danger' | null>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const email = e.currentTarget.email.value;
      const url = 'https://a.klaviyo.com/client/subscriptions/?company_id=HM6FU2';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'revision': '2023-07-15',
        },
        body: JSON.stringify({
          data: {
            type: 'subscription',
            attributes: {
              profile: {
                data: {
                  type: 'profile',
                  attributes: {
                    email: email
                  }
                }
              }
            },
            relationships: {
              list: {
                data: {
                  type: 'list',
                  id: listId,
                }
              }
            }
          }
        })
      });

      const data = await response;
      if (data?.status >= 200 && data?.status < 300) {
        setStatus('success');
        setStatusMessage('Thank you for subscribing!');
      } if (data?.status >= 400) {
        const error = await data.json();
        setStatus('danger');
        setStatusMessage(error?.errors?.[0]?.detail);
      }
    } catch (error: any) {
      setStatus('danger');
      setStatusMessage('Something went wrong. Please try again.')
    }
    
  };
  return (
    <Container>
      <FormContainer onSubmit={handleSubmit} direction={direction}>
        <Input
          type="email"
          id="email"
          aria-label="email"
          autoComplete='email'
          placeholder={placeholder}
          required
          color={color}
          direction={direction}
        />
        {direction === 'column' && <Spacer spacing="sm3" />}
        <Button type="submit" buttonColor={buttonColor} borderRadius={borderRadius} >
          <BodyText textColor="white" alignment={direction === 'column' ? 'center' : 'left' }>
            SUBMIT
          </BodyText>
        </Button>
      </FormContainer>
      <Spacer spacing="sm3" />
      {statusMessage && status && (
        <>
          <BodyText textColor={status!} alignment={direction === 'column' ? 'center' : 'left' }>
            {statusMessage}
          </BodyText>
          <Spacer spacing="sm3" />
        </>
      )}
    </Container>

  )
};

export default NewsLetterForm;