import { css } from 'styled-components';
import { generateResponsiveProps, CustomInterpolation, ThemeObject, FontSizeProp } from '../../utils/responsive-props';

export type Level = '1' | '2' | '3' | '4' | '5' | '6';
type LevelMap = {
  [K in Level]: FontSizeProp;
};

export const levelMap: LevelMap = {
  '1': [null, 'f9', 'f10', null, 'f11'],
  '2': [null, 'f8', 'f9', null, 'f10'],
  '3': [null, 'f7', 'f8', null, 'f9'],
  '4': [null, 'f6', 'f7', null, 'f8'],
  '5': [null, 'f5', 'f6', null, 'f7'],
  '6': [null, 'f4', 'f5', null, 'f6'],
};

/**
 * Made the heading levels exportable, with baked in responsive font sizes.
 * This provides a 1-1 with the headings defined in zero-height, see:
 * https://zeroheight.com/38ec2af7d/p/72fea5-headings/t/54a85d
 */
export const Level1 = css`
  ${({ theme: { fontSizes } }): CustomInterpolation =>
    generateResponsiveProps<FontSizeProp>({
      cssProperty: 'font-size',
      prop: levelMap['1'],
      themeObject: fontSizes as ThemeObject,
    })};
`;

export const Level2 = css`
  ${({ theme: { fontSizes } }): CustomInterpolation =>
    generateResponsiveProps<FontSizeProp>({
      cssProperty: 'font-size',
      prop: levelMap['2'],
      themeObject: fontSizes as ThemeObject,
    })};
`;

export const Level3 = css`
  ${({ theme: { fontSizes } }): CustomInterpolation =>
    generateResponsiveProps<FontSizeProp>({
      cssProperty: 'font-size',
      prop: levelMap['3'],
      themeObject: fontSizes as ThemeObject,
    })};
`;

export const Level4 = css`
  ${({ theme: { fontSizes } }): CustomInterpolation =>
    generateResponsiveProps<FontSizeProp>({
      cssProperty: 'font-size',
      prop: levelMap['4'],
      themeObject: fontSizes as ThemeObject,
    })};
`;

export const Level5 = css`
  ${({ theme: { fontSizes } }): CustomInterpolation =>
    generateResponsiveProps<FontSizeProp>({
      cssProperty: 'font-size',
      prop: levelMap['5'],
      themeObject: fontSizes as ThemeObject,
    })};
`;

export const Level6 = css`
  ${({ theme: { fontSizes } }): CustomInterpolation =>
    generateResponsiveProps<FontSizeProp>({
      cssProperty: 'font-size',
      prop: levelMap['6'],
      themeObject: fontSizes as ThemeObject,
    })};
`;
