import React, { FC, useState, Fragment } from 'react';
import { css } from 'styled-components';
import { MenuData } from '../Navigation';
import BodyText from '../../BodyText';
import Logo from '../../../assets/Logo';
import Link from '../../Link';
import PlusIcon from '../../../assets/Plus';
import MinusIcon from '../../../assets/Minus';
import Cart from '../Cart';
import * as S from './MobileNav-styled';


interface MobileNavigationProps {
  data?: MenuData;
  backgroundColor?: string;
}

const MobileNavigation: FC<MobileNavigationProps> = ({ data, backgroundColor = "offWhite" }) => {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isShopNavOpen, setIsShopNavOpen] = useState(false);
  const textColor = backgroundColor === 'navy' ? 'offWhite' : 'navy';

  const handleNavigation = () => setIsNavOpen(!isNavOpen);
  const handleShopNav = () => setIsShopNavOpen(!isShopNavOpen);

  return (
    <>
      <S.Container backgroundColor={backgroundColor}>
        <S.Button onClick={handleNavigation} style={{ borderBottom: 'transparent'}}>
          <BodyText fontFamily="parnaso" textColor={textColor}>
            Menu
          </BodyText>
        </S.Button>
        <Link url="/">
          <Logo width="12.125rem" height="4.313rem" fill={backgroundColor === 'navy' ? '#F5F3EE' : '#001831'}/>
        </Link>
        <S.CartContainer>
          <Cart textColor={textColor} />
        </S.CartContainer>
      </S.Container>
      <S.SideNav isOpen={isNavOpen}>
        <S.SideNavSubContainer>
          <S.Button onClick={handleNavigation}>
            <BodyText>
              CLOSE
            </BodyText>
          </S.Button>
          <S.Button
            as={Link}
            backgroundColor="navy"
            url="/collections"
          >
            <BodyText textColor="offWhite">
              SHOP ALL
            </BodyText>
          </S.Button>
          {data?.menu_links?.map(items => {
            if (items.label === 'Shop') {
              return (
                <Fragment key="shop">
                  <S.Button
                    key={items.label}
                    onClick={handleShopNav}
                    css={css`
                      border-bottom: solid 1px ${({ theme }) => theme.colors.navy};
                      display: flex;
                      justify-content: space-between;
                      align-items: center;
                    `}
                  >
                    <BodyText textTransform="uppercase">{items.label}</BodyText>
                    {isShopNavOpen ? <MinusIcon /> : <PlusIcon />}
                  </S.Button>
                  <S.ShopNavContainer isOpen={isShopNavOpen}>
                    {data.submenu_column_1.map((items, index) => (
                      <S.Button
                        as={Link}
                        url={items?.link.url ?? ""}                
                        css="border-bottom: solid 1px #ADB3B9; padding-left: 0;"
                        key={`${items?.link.url}+${index}`}
                      >
                        <BodyText textTransform="uppercase">{items.label}</BodyText>
                      </S.Button>
                    ))}
                    {data.submenu_column_2.map((items) => (
                      <S.Button
                        as={Link}
                        url={items?.link.url ?? ""}                
                        css="border-bottom: solid 1px #ADB3B9; padding-left: 0;"
                        key={items.label}
                      >
                        <BodyText textTransform="uppercase">{items.label}</BodyText>
                      </S.Button>
                    ))}
                  </S.ShopNavContainer>
                </Fragment>
              )
            }
            return (
              <S.Button
                as={Link}
                url={items?.link.url ?? ""}  
                key={items.label}
              >
                <BodyText textTransform="uppercase">{items.label}</BodyText>
              </S.Button>
            )
          })}
          <S.Button
            as={Link}
            url="https://account.orcecosmetics.com/account"
          >
            <BodyText textColor="navy">
              ACCOUNT
            </BodyText>
          </S.Button>
          <S.Button
            as={Link}
            url="https://www.instagram.com/orcecosmetics"
            target="_blank"
          >
            <BodyText textColor="navy">
              INSTAGRAM
            </BodyText>
          </S.Button>
          <S.Button
            as={Link}
            url="https://www.facebook.com/orcecosmetics"
            target="_blank"
          >
            <BodyText textColor="navy">
              FACEBOOK
            </BodyText>
          </S.Button>
          <S.Button
            as={Link}
            url="https://www.tiktok.com/@orce.cosmetics"
            target="_blank"
          >
            <BodyText textColor="navy">
              TIKTOK
            </BodyText>
          </S.Button>
        </S.SideNavSubContainer>
      </S.SideNav>
    </>
  )
};

export default MobileNavigation;