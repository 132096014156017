import styled from 'styled-components';
import { up } from 'styled-breakpoints';

interface SubNavProps {
  isOpen: boolean;
}

export const Container = styled.footer`
  position: relative;
  background-color: ${({ theme }): string => theme.colors.navy};
  padding: 4.688rem 0 10rem;
  overflow: hidden;
`;

export const SubContainer = styled.div`
  padding: 0 1rem;
  display: flex;
  flex-direction: column;

  ${up('tablet-portrait')} {
    padding: 0 1.875rem;
  }
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid white;

`;

export const Button = styled.button`
  padding: 1.375rem 1rem;
  border-top: 1px solid white;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;


export const SubNavContainer = styled.div<SubNavProps>`
  display: flex;
  flex-direction: column;
  padding: 0 1rem;
  max-height: ${({ isOpen }) => isOpen ? '100rem' : '0'};
  overflow: hidden;
  transition: max-height 0.3s ease-in-out;
`;

export const LogoContainer = styled.div`
  position: absolute;
  width: 100%;
  max-width: 27.438rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  left: 50%;
  transform: translateX(-50%);
  bottom: -3rem;
`;