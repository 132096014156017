import { FontSizes } from '../../system';

const fontSizes: FontSizes = {
  f0: '0.625rem' /* 10px */,
  f1: '0.75rem' /* 12px */,
  f2: '0.875rem' /* 14px */,
  f3: '1rem' /* 16px */,
  f4: '1.125rem' /* 18px */,
  f5: '1.3125rem' /* 21px */,
  f6: '1.5rem' /* 24px */,
  f7: '1.75rem' /* 28px */,
  f8: '2rem' /* 32px */,
  f9: '2.25rem' /* 36px */,
  f10: '2.625rem' /* 42px */,
  f11: '3rem' /* 48px */,
  f12: '3.5rem' /* 56px */,
  f13: '4rem' /* 64px */,
  f14: '4.5rem' /* 72px */,
  f15: '5.25rem' /* 84px */,
  f16: '6rem' /* 96px */,
};

export default fontSizes;
