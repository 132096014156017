import React, { FC } from 'react';
import { between, up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import RichText from '../../components/RichText';
import Spacer from '../../components/Spacer';
import * as S from './FancyBlock-styled';

const FancyBlock: FC<{ data: any }> = ({ data: { primary } }) => {
  const { backgroundColor, title, description, image } = primary;
  const isTabletPortrait = useBreakpoint(between('tablet-portrait', 'tablet-landscape'));
  const isTabletUp = useBreakpoint(up('tablet-portrait'));

  return (
    <S.Container>
      {isTabletPortrait && (
        <>
        <RichText
          document={title.richText}
          textColor={backgroundColor === "navy" ? "white" : "navy"}
          textSize="f10"
        />
        <Spacer spacing="md2" />
        </>
      )}
      <S.SubContainer>
        <S.ContentWrapper>
          <S.ContentContainer>
            {isTabletUp ? (
              <S.OutlineContainer backgroundColor={backgroundColor}>
                <S.TitleContainer backgroundColor={backgroundColor}>
                  <RichText
                    document={title.richText}
                    textColor={backgroundColor === "navy" ? "white" : "navy"}
                    alignment="center"
                    textSize="f10"
                  />
                </S.TitleContainer>
              </S.OutlineContainer>
            ) : (
              <S.MobileTitleContainer>
                <RichText
                  document={title.richText}
                  textColor={backgroundColor === "navy" ? "white" : "navy"}
                  textSize="f8"
                />
              </S.MobileTitleContainer>
            )}
            <S.DescriptionContainer>
              <RichText
                document={description.richText}
                textColor={backgroundColor === "navy" ? "white" : "navy"}
              />
            </S.DescriptionContainer>
          </S.ContentContainer>
        </S.ContentWrapper>
        <S.ImageWrapper>
          <S.ImageContainer>
            <GatsbyImage
              image={image.gatsbyImageData}
              alt=""
              imgStyle={{ borderTopRightRadius: '33% 31%' }}
            />
          </S.ImageContainer>
          <S.MobileImageOutline />
        </S.ImageWrapper>
      </S.SubContainer>
      {primary?.hero_title?.richText && (
        <S.HeroTitleContainer>
          <Spacer spacing={[null, null, 'lg3', 'xlg1']} />
          <RichText
            document={primary?.hero_title?.richText}
            textColor={backgroundColor === "navy" ? "white" : "navy"}
            textSize={[null, null, "f14","f16"]}
            alignment="center"
          />
          <Spacer spacing="md2" />
        </S.HeroTitleContainer>
      )}
    </S.Container>
  );
};

export default FancyBlock;
