import React, { FC } from 'react';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up } from 'styled-breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';
import { useInView } from 'react-intersection-observer';
import { Data } from '../../components/Slices/Slices';
import Spacer from '../../components/Spacer';
import RichText from '../../components/RichText';
import BodyText from '../../components/BodyText';
import GraphContent from './GraphContent'
import MobileScienceSection from './MobileScienceSection';
import * as S from './ScienceSection-styled';

const ScienceSection: FC<Data> = ({ data }) => {
  const isTabletUp = useBreakpoint(up('tablet-landscape'));
  const { ref: ref1, inView: inView1 } = useInView({ threshold: 0.15});
  const { ref: ref2, inView: inView2 } = useInView({ threshold: 0.15});
  const { items, primary } = data ?? {};
  const {
    graph_1_description: description1,
    graph_1_percentage: percentage1,
    graph_2_description: description2,
    graph_2_percentage: percentage2,
    graph_3_description: description3,
    graph_3_percentage: percentage3,
    infographic_image: image,
  } = primary ?? {};

  const column1 = items.slice(0, Math.round(items.length / 2));
  const column2 = items.slice(Math.round(items.length / 2));

  const mobileData = [...items];
  const mobileGraphData = [
    { description: description1.richText, percentage: percentage1 },
    { description: description2.richText, percentage: percentage2 },
    { description: description3.richText, percentage: percentage3},
  ];

  return (
    <>
      <S.Container>
      <Spacer spacing="md4" />
        <S.TitleContainer>
          <BodyText fontFamily="parnaso" textSize={[null, null, "f7"]} lineHeight="1">
            OVERLOOKED <i>and</i> UNDERREPRESENTED
          </BodyText>
          <Spacer spacing="md2" />
          <BodyText textColor="midGray">
          We surveyed 357 Asian women and learned that:
          </BodyText>
        </S.TitleContainer>
        {isTabletUp ? (
          <>
            <S.SubContainer ref={ref1} inView={inView1}>
            <S.ResultContainer>
              <GraphContent
                percentage={percentage1}
                description={description1.richText}
                delay={0}
                inView={inView1}
              />
              <GraphContent
                percentage={percentage2}
                description={description2.richText}
                delay={300}
                inView={inView1}
              />
              <GraphContent
                percentage={percentage3}
                description={description3.richText}
                delay={600}
                inView={inView1}
              />
            </S.ResultContainer>
            </S.SubContainer>
            <Spacer spacing="md3" />
            <S.ContentContainer ref={ref2} inView={inView2}>
              <S.ContentWrapper>
                <S.ContentColumn alignment="right">
                  {column1.map((item, index) => (
                    <div key={`column-1-${index}`}>
                      <RichText
                        document={item.feature_title.richText}
                        alignment="right"
                        textSize="f3"
                        fontFamily="sourceSansBold"
                      />
                      <Spacer spacing="sm2" />
                      <RichText document={item.feature_description.richText} alignment="right" />
                      {column1.length - 1 !== index && <Spacer spacing="md3" />}
                    </div>
                  ))}
                </S.ContentColumn>
                <S.ImageContainer>
                  <S.ImageWrapper>
                    <GatsbyImage image={image.gatsbyImageData} alt="" />
                  </S.ImageWrapper>
                </S.ImageContainer>
                <S.ContentColumn>
                {column2.map((item, index) => (
                    <div key={`column-2-${index}`}>
                      <RichText
                        document={item.feature_title.richText}
                        textSize="f3"
                        fontFamily="sourceSansBold"
                      />
                      <Spacer spacing="sm2" />
                      <RichText document={item.feature_description.richText} />
                      {column2.length - 1 !== index && <Spacer spacing="md3" />}
                    </div>
                  ))}
                </S.ContentColumn>
              </S.ContentWrapper>
            </S.ContentContainer>
          </>
        ) : (
          <MobileScienceSection data={mobileData} graphData={mobileGraphData} />
        )}
      </S.Container>
      <Spacer spacing="md3" />
    </>
  )
};

export default ScienceSection;