import React, { FC } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { GatsbyImage } from 'gatsby-plugin-image';
import Spacer from '../../components/Spacer';

const Container = styled.section`
  overflow: hidden;
  margin: 0 1rem;

  ${up('phone')} {
    margin: 0 1.5rem;
  }

  ${up('tablet-portrait')} {
    margin: 0 1.875rem;
  }
`;

const NarrowHeader: FC<{ data: any }> = ({ data: { primary } }) => {
  const { desktop_image, mobile_image } = primary;
  const isTabletUp = useBreakpoint(up('tablet-portrait'));

  const mobileStyle = {
    borderBottomRightRadius: '32% 30%'
  };

  const desktopStyle = {
    borderBottomLeftRadius: '23% 67%',
  }

  return (
    <>
      <Container>
        <GatsbyImage
          image={isTabletUp ? desktop_image.gatsbyImageData : mobile_image.gatsbyImageData}
          alt=""
          imgStyle={isTabletUp ? desktopStyle : mobileStyle}
        />
      </Container>
      <Spacer spacing={isTabletUp ? 'lg1' : 'md1'} />
    </>
  )
};

export default NarrowHeader;