import { Colors } from '../../system';

const colors: Colors = {
    // primary palette
  offWhite: '#F5F3EE',
  navy: '#001831',
  gold: '#C79C5E',
  gray: '#ADB3B9',
  red: '#CB4646',
  black: '#000',
  white: '#FFF',
  transparent: 'transparent',
  // grayscale
  nearWhite: '#F9F9F9',
  lightGray: '#E0E0E0',
  moonGray: '#CCC',
  lightSilver: '#AAA',
  silver: '#999',
  midGray: '#7B858F',
  darkGray: '#4A4A4A',
  nearBlack: '#222',
  // status colors
  success: '#2AC278',
  warning: '#FF9B00',
  danger: '#D52B1E',
  // neutral: '#0077BB',
  // // focus / active colors,
  active: '#B9CDF1',
  // // expanded palette
  // blue: '#0077BB', // aka: lake placid
  // sonicBlue: '#AADDDD',
  // green: '#4AA262', // aka: ui green
  // surfGreen: '#AAEEBB',
  // butterScotchBlonde: '#E08A00',
  // orange: '#FF9B00', // aka: capri orange
  // flashPink: '#FF88AA',
  // shellPink: '#FFBBBB',
  // purple: '#262e8d',
};

export default colors;
