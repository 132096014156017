import React, { FC } from 'react';
import styled from 'styled-components';


const Container = styled.div`
  max-width: 600px;
  margin: auto;
`;

const CodeSnippet: FC<{ data: any }> = ({ data }) => {
  const { snippet } = data?.primary ?? {};
  return (
    <Container>
      <div dangerouslySetInnerHTML={{__html: snippet.raw[0].text}} />
    </Container>
  )
};


export default CodeSnippet;
