import styled from 'styled-components';

interface StyleProps {
  outlineColor: string
  isLast?: boolean;
}

export const Button = styled.button<{ hasPadding?: boolean | null }>`
  padding: 1.375rem ${({ hasPadding }) => hasPadding ? '1rem' : '0'};
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ContentContainer = styled.div<StyleProps>`
  padding: 0.25rem 2rem 1rem;
`;
