import React, { FC, useState } from 'react';
import styled from 'styled-components';
import BodyText from '../BodyText';
import Spacer from '../Spacer';
import Heading from '../Heading';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 1rem;
  justify-content: center;
  align-items: center;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const FormContainer = styled.form`
  display: flex;
  flex-direction: column;
  max-width: 500px;
  width: 100%;
`;

const NameContainer = styled.div`
  display: flex;
`;

const Input = styled.input`
  -webkit-appearance: none;
  background-color: transparent;
  border: solid 1px ${({ theme }) => theme.colors.navy};
  border-radius: 5px;
  padding: 10px;
  color: navy;
  font-size: ${({ theme }) => theme.fontSizes.f3};
  width: 100%;
  flex: 1;


  &::placeholder {
    color: ${({ theme }) => theme.colors.silver};
  }

  &:hover,
  :focus-visible {
    box-shadow: 0 0 5px rgb(0, 100, 205);
    border-color: ${({ theme }) => theme.colors.navy};
    outline: none;
  }
`;

const Button = styled.button`
  width: 100%;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.navy};
  border-radius: 5px;
`;

const StatusContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 219px;
  padding: 0 1rem;
`;

const GiveawayForm: FC<{ data: any }> = ({ data }) => {
  const { list_id } = data?.primary ?? {};
  const [status, setStatus] = useState<null | 'success' | 'error'>(null);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const firstName = e.currentTarget.firstName.value;
      const lastName = e.currentTarget.lastName.value;
      const email = e.currentTarget.email.value;
      const url = 'https://a.klaviyo.com/client/subscriptions/?company_id=HM6FU2';
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'revision': '2023-07-15',
        },
        body: JSON.stringify({
          data: {
            type: 'subscription',
            attributes: {
              profile: {
                data: {
                  type: 'profile',
                  attributes: {
                    email: email,
                    first_name: firstName,
                    last_name: lastName,
                  }
                }
              }
            },
            relationships: {
              list: {
                data: {
                  type: 'list',
                  id: list_id,
                }
              }
            }
          }
        })
      });

      const data = await response;
      if (data?.status >= 200 && data?.status < 300) {
        setStatus('success');
      };
    } catch (error: any) {
        setStatus('error');
    }
  };

  if (status === 'success') {
    return (
      <StatusContainer>
        <BodyText textSize="f7" weight="bold" alignment="center">
          THANKS FOR ENTERING!
        </BodyText>
        <Spacer spacing="md2" />
        <BodyText textSize="f3" alignment="center">
          We wish you luck. 💙
        </BodyText>
      </StatusContainer>
    )
  }

  if (status === 'error') {
    return (
      <StatusContainer>
        <Spacer spacing="md2" />
        <BodyText textSize="f4" alignment="center">
          Something went wrong. Please try again.
        </BodyText>
      </StatusContainer>
    )
  }

  return (
    <Container>
      <Heading as="h2" textSize="f5" alignment="center" fontFamily='helvetica' >
        ENTER FOR A CHANCE TO WIN
      </Heading>
      <Spacer spacing="md2"/>
      <FormContainer onSubmit={handleSubmit}>
        <NameContainer>
          <SubContainer>
            <label htmlFor="firstName">
              <BodyText textTransform='uppercase'>First Name</BodyText>
            </label>
            <Input
              type="text"
              id="firstName"
              name="firstName"
              aria-label="First Name"
              autoComplete="given-name"
              placeholder="First Name"
              required
            />
          </SubContainer>
          <Spacer spacing="md2" direction="vertical" />
          <SubContainer>
            <label htmlFor="lastName">
              <BodyText textTransform='uppercase'>Last Name</BodyText>
            </label>
            <Input
              type="text"
              id="lastName"
              name="lastName"
              aria-label="Last Name"
              autoComplete="family-name"
              placeholder="Last Name"
              required
            />
          </SubContainer>
        </NameContainer>
        <Spacer spacing="md1" />
        <label htmlFor="email">
          <BodyText textTransform='uppercase'>Email Address</BodyText>
        </label>
        <Input
          type="email"
          name="email"
          id="email"
          aria-label="email"
          autoComplete='email'
          placeholder="Email"
          required
        />
        <Spacer spacing="md3" />
        <Button type="submit">
          <BodyText textColor='white'>
            ENTER NOW
          </BodyText>
        </Button>
      </FormContainer>
      <Spacer spacing="sm3" />
    </Container>

  )
};

export default GiveawayForm;
