import React, { FC } from 'react';
import CicularProgressBar from '../../../components/CircularProgressBar';
import RichText from '../../../components/RichText';
import Spacer from '../../../components/Spacer';
import BodyText from '../../../components/BodyText';
import * as S from './GraphContent-styled';

interface GraphContentProps {
  percentage: number;
  description: any;
  delay?: number;
  inView: boolean;
}

const GraphContent: FC<GraphContentProps> = ({ percentage, description, delay = 0, inView }) => {
  return (
    <S.ResultWrapper>
      <S.MetaContainer>
        <Spacer spacing={[null, 'sm2', 'md2', 'md4']} />
        <CicularProgressBar
          percentage={percentage}
          inView={inView}
          delay={delay}
        />
        <Spacer spacing={["sm1", "sm1", "sm1", "md1"]} />
        <BodyText
          textSize="f10"
          fontFamily="parnaso"
          css={`font-style: italic; line-height: 1;`}
        >
          {percentage}%
        </BodyText>
        <Spacer spacing='md1' />
        <RichText
          document={description}
          alignment="center"
          maxWidth={[null, null, "90%", "98%", "100%"]}
        />
        <Spacer spacing={[null, 'sm2', 'md2', 'md4']} />
      </S.MetaContainer>
    </S.ResultWrapper>
  )
}

export default GraphContent;