exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-collections-tsx": () => import("./../../../src/pages/collections.tsx" /* webpackChunkName: "component---src-pages-collections-tsx" */),
  "component---src-pages-find-your-shade-tsx": () => import("./../../../src/pages/find-your-shade.tsx" /* webpackChunkName: "component---src-pages-find-your-shade-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-preview-js": () => import("./../../../src/pages/preview.js" /* webpackChunkName: "component---src-pages-preview-js" */),
  "component---src-templates-page-page-tsx": () => import("./../../../src/templates/Page/Page.tsx" /* webpackChunkName: "component---src-templates-page-page-tsx" */),
  "component---src-templates-product-detail-page-product-detail-page-tsx": () => import("./../../../src/templates/ProductDetailPage/ProductDetailPage.tsx" /* webpackChunkName: "component---src-templates-product-detail-page-product-detail-page-tsx" */)
}

