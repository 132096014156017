import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints'; 
import BodyText from '../../../BodyText';
import Spacer from '../../../Spacer';
import { StoreContext } from '../../../../context/StoreContext';
import { IGatsbyImageData, GatsbyImage } from 'gatsby-plugin-image';

interface RouteCardProps {
  image: IGatsbyImageData;
  price?: string;
  loading?: boolean;
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

`;

const Container = styled.div`
  display: flex;
  align-items: top;
  padding: 1rem 1rem 0;
  ${up('tablet-portrait')} {
    padding: 1.875rem 1.5rem 0;
  }
`;

const ImageContainer = styled.div`
${up('tablet-portrait')} {
  padding: 0 0.25rem;
`;

const Image = styled(GatsbyImage)`
  mix-blend-mode: multiply;
  height: 30px;
  width: 30px;
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  max-width: 80%;
`;

const SwitchContainer = styled.div`
  display: flex;
  align-items: center;
  padding: 0 1rem;
  ${up('tablet-portrait')} {
    padding: 0 1.5rem;
  }
`;

const PriceContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 50px;
`;

const Label = styled.label`
  position: relative;
  display: inline-block;
  width: 38px;
  height: 22px;

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .3s;
    border-radius: 34px;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 16px;
    width: 16px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
    border-radius: 50%;
  }

  > input {
    visibility: hidden;
  }

  > input:checked + .slider {
    background-color: ${({ theme }) => theme.colors.navy};
  };

  > input:focus + .slider {
    box-shadow: 0 0 1px #2196F3;
  }
  
  > input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }
`;


const RouteCard: FC<RouteCardProps> = ({ image, price, loading }) => {
  const { withInsurance, handleWithInsurance } = useContext(StoreContext);

  return (
    <Wrapper>
      <Container>
        <ImageContainer>
          <Image image={image} alt=""/>
        </ImageContainer>
        <ContentContainer>
          <BodyText textTransform='uppercase' textSize="f2" weight='bold' textColor='midGray'>
            Package Protection
          </BodyText>
          <Spacer spacing="sm2" />
          <BodyText textSize="f1" textColor='midGray' lineHeight='1.2' maxWidth="90%">
            Against loss, theft, or damage in transit, and instant resolution with <a href="https://shoppers.help.route.com/hc/en-us">Route</a>.
          </BodyText>
          <Spacer spacing="sm3" />
        </ContentContainer>
        <PriceContainer>
          {withInsurance && (
            <BodyText textTransform='uppercase' textSize="f2" textColor='darkGray'>
              {loading? '...' :`$${price}`}
            </BodyText>
          )}
        </PriceContainer>
      </Container>
      <SwitchContainer>
        <Label>
          <input type="checkbox" checked={withInsurance} onChange={handleWithInsurance} />
          <span className="slider" />
        </Label>
        <Spacer spacing="sm3" direction="vertical" />
        <BodyText textSize="f5" textColor='midGray' maxWidth="300px" lineHeight='1.2' style={{ alignSelf: 'baseline' }}>
          *
        </BodyText>
        <Spacer spacing="sm2" direction="vertical" />
        <BodyText textSize="f1" textColor='midGray' maxWidth="75%" lineHeight='1.2'>
          By deselecting package protection, Orcé Cosmetics is not liable for lost, damaged, or stolen items.
        </BodyText>
      </SwitchContainer>
      <Spacer spacing="lg3"/>
    </Wrapper>
  );
};

export default RouteCard;