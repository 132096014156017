import React, { FC, useState } from 'react';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import { up } from 'styled-breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image'; 
import BodyText from '../../components/BodyText';
import Spacer from '../../components/Spacer';
import RichText from '../../components/RichText';
import * as S from './IngredientsSection-styled';


interface IngredientsSectionProps {
  data: any;
}

const IngredientsSection: FC<IngredientsSectionProps> = ({ data }) => {
  const isTabletUp = useBreakpoint(up('tablet-portrait'));
  const [isOpen, setIsOpen] = useState(false);
  const { primary, items } = data;
  const checkSignUrl = primary?.['we_are_sign']?.fixed?.srcWebp;
  const prohibitionSignUrl = primary?.['made_without_sign']?.fixed?.srcWebp;

  const handleClick = () => {
    setIsOpen(!isOpen);
  }
  return (
    <S.Container>
      <S.SubContainer>
        <S.IngredientHeaderContainer>
          <BodyText as="h2" textColor="white" fontFamily="parnaso" textSize={[ null, null, "f7","f9"]} marginBottom="md1">
            {primary?.title?.text}
          </BodyText>
          <S.Button onClick={handleClick}>
            <BodyText textColor={isTabletUp ? 'white' : 'navy'} >
              {isOpen ? 'SEE LESS INGREDIENTS' : 'SEE FULL INGREDIENTS'}
            </BodyText>
          </S.Button>
        </S.IngredientHeaderContainer>
        <Spacer spacing="md2" />
        {isOpen && (
          <>
            <RichText
              document={primary?.full_ingredients_content?.richText}
              textColor="white"
              marginBottom="md2"
            />
            <S.ListContainer>
              <S.List image={prohibitionSignUrl}>
                <RichText document={primary?.made_without_list?.richText} textColor="white" />
              </S.List>
              <S.List image={checkSignUrl}>
                <RichText document={primary?.we_are_list?.richText} textColor="white" />
              </S.List>
            </S.ListContainer>
            <Spacer spacing="lg1" />
          </>
        )}
        <S.Grid>
          {items.map((ingredient: any) => {
            return (
              <S.Card key={ingredient.title}>
                <S.ImageMetaContainer>
                  <S.ImageContainer>
                    <GatsbyImage image={ingredient?.image?.gatsbyImageData} alt={`${ingredient.title}`} />
                  </S.ImageContainer>
                  <Spacer spacing="md3" direction="vertical"/>
                  <BodyText textColor="white" maxWidth="10ch">
                    {ingredient?.title}
                  </BodyText>
                </S.ImageMetaContainer>
                <Spacer spacing="md2" />
                <BodyText textColor="white">
                  {ingredient?.description}
                </BodyText>
              </S.Card>
            )
          })}
        </S.Grid>
      </S.SubContainer>
    </S.Container>
  )
};

export default IngredientsSection;
