import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import RichText from '../../components/RichText';
import Spacer from '../../components/Spacer';
import * as S from './MeetTheFounder-styled';

interface MeetTheFounderProps {
  data: any;
}

const MeetTheFounder: FC<MeetTheFounderProps> = ({ data: { primary } }) => {
  const { backgroundColor, description, title, image } = primary;
  const isTabletUp = useBreakpoint(up('tablet-portrait'));

  return(
    <S.Container>
      <S.SubContainer>
        <S.ImageContainer>
          <GatsbyImage
            image={image.gatsbyImageData} alt=""
            imgStyle={{ borderTopLeftRadius: '33% 28%' }}
          />
        </S.ImageContainer>
        <S.TitleContainer>
          <RichText
            document={title.richText}
            textColor={backgroundColor === 'navy' ? "white" : 'white'}
            alignment={isTabletUp ? "center" : "left"}
            textSize={[null, "f8", "f14", "f15", "f16"]}
          />
        </S.TitleContainer>
      </S.SubContainer>
      <Spacer spacing={isTabletUp ? "lg2" : "md2"} />
      <S.DescriptionContainer>
        <RichText
          document={description.richText}
          textColor={backgroundColor === 'navy' ? 'white' : 'navy'}
          textSize={[null,"f3", "f5"]}
          fontFamily={isTabletUp ? "parnaso" : 'helvetica'}
          lineHeight="1.5"
          maxWidth="720px"
        />
      </S.DescriptionContainer>
    </S.Container>
  );
};

export default MeetTheFounder;
