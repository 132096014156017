const linkResolver = (doc: any) => {
  if (doc.type === 'homepage') {
    return '/';
  }

  if (doc.type === 'pages') {
    return `/${doc.uid}/`;
  }
  return '/';
};

export default linkResolver;

