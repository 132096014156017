import React, { FC } from 'react';
import Logo from '../../../assets/Logo';
import BodyText from '../../BodyText';
import Spacer from '../../Spacer';
import * as S from './DesktopFooter-styled';
import { FooterProps } from '../Footer'; 
import Link from '../../Link';
import NewsLetterForm from '../../NewsLetterForm';


const DesktopFooter: FC<FooterProps> = ({
  exploreList,
  connectList,
  resourcesList,
  year,
  formDescription
}) => {
  return (
    <S.Container>
      <S.SubContainer>
        <S.ContentContainer>
          <S.SignUpContainer>
            <BodyText textColor="white" fontFamily="parnaso" textSize="f7" lineHeight="1.1">
              HERE, <i>you</i> ARE CELEBRATED, ACKNOWLEDGED, AND SEEN.
            </BodyText>
            <Spacer spacing="sm3" />
            <BodyText textColor="white">
              {formDescription}
            </BodyText>
            <Spacer spacing="md1" />
            <NewsLetterForm />
            <Spacer spacing="md1" />
            <BodyText textColor="white">
              {`©${year} Orcé Cosmetics`}
            </BodyText>
          </S.SignUpContainer>
          <S.MenuContainer>
            <S.Column>
              <BodyText textColor="white">
                EXPLORE
              </BodyText>
              <Spacer spacing="md1" />
              {exploreList.map((item) => (
                <Link url={item.link} key={`${item.name}`}> 
                  <BodyText textColor="white">
                    {item.name}
                  </BodyText>
                </Link>
              ))}
            </S.Column>
            <Spacer spacing="md3" direction="vertical" />
            <S.Column>
              <BodyText textColor="white">
                CONNECT
              </BodyText>
              <Spacer spacing="md1" />
              {connectList.map((item) => (
                <Link url={item.link} key={item.name}> 
                <BodyText textColor="white">
                  {item.name}
                </BodyText>
              </Link>
              ))}
              <Spacer spacing="lg2" />
              <BodyText textColor="white">
                SUPPORT
              </BodyText>
              <Spacer spacing="md1" />
              {resourcesList.map((item) => (
                <Link url={item.link} key={item.name}> 
                  <BodyText textColor="white">
                    {item.name}
                  </BodyText>
                </Link>
              ))}
            </S.Column>
          </S.MenuContainer>
        </S.ContentContainer>
        <S.LogoContainer>
          <Logo fill="white" width="113vw" height="100%" style={{maxWidth: "1630px"}} />
        </S.LogoContainer>
      </S.SubContainer>
    </S.Container>
  )
};

export default DesktopFooter;
