import styled from 'styled-components';

export const SubNavContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

export const SubMenuContainer = styled.div<{ backgroundColor: string }>`
  position: absolute;
  top: 60px;
  width: 100%;
  padding-left: 9.313rem;
  min-height: 22.5rem;
  display: grid;
  grid-template-columns: 16.875rem 16.875rem auto;
  column-gap: 1rem;
  border-bottom: 1px solid ${({ theme }): string => theme.colors.navy};
  background-color: ${({ theme, backgroundColor }): string => backgroundColor === 'offWhite' ? theme.colors.offWhite : theme.colors.navy};
  z-index: 99;
`;

export const Columm = styled.div`
  height: 360px;
  width: 100%;
`;

export const NavItem = styled.li`
  padding-right: 1rem;
`

export const AdContainer = styled.div`
  display: none;

  @media screen and (min-width: 1053px) {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-direction: row-reverse;
  }
`

export const ImageContainer = styled.div` 
  position: absolute;
  top: -3.75rem;
  width: 26.25rem;
`;

export const Button = styled.div`
  position: absolute;
  background-color: ${({ theme }) => theme.colors.offWhite};
  bottom: 2rem;
  right: 2rem;
  width: 22.25rem;
  transition: background-color 0.3s;

  &:hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.navy};
  }
`;


export const Blur = styled.div`
  width: 100%;
  height: calc(100vh - 22.5rem);
  background-color: rgba(245, 243, 238, 0.3);
  backdrop-filter: blur(7px);
  position: fixed;
  bottom: 0;
`;