import React, { FC } from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import RichText from '../../components/RichText';
import Spacer from '../../components/Spacer';

import * as S from './ImageText-stlyled';
import BodyText from '../../components/BodyText';

interface ImageTextProps {
  data: any;
};

const ImageText: FC<ImageTextProps> = ({ data: { items, backgroundColor  = 'offWhite'} }) => {

  return (
    <S.Container>
      <S.SubContainer>
        {items.map((item: any, index: number) => (
          <S.GridContainer key={`grid-${index}`}>
            <S.ImageContainer alignment={item?.aligment}>
              <GatsbyImage image={item.image.gatsbyImageData} alt="" imgStyle={{objectPosition: '50% 50%'}} />
            </S.ImageContainer>
            <S.ContentContainer backgroundColor={backgroundColor}>
              <RichText
                document={item.title.richText}
                textColor={backgroundColor === 'navy' ? 'white' : 'navy'}
                maxWidth="100%"
                width="100%"
                textSize={[null, "f7","f8" ]}
              />
              <Spacer spacing="sm4" />
              {item.tags && (
                <>
                {item.tag_title && (
                  <>
                    <BodyText textSize="f1">
                      {item.tag_title}
                    </BodyText>
                    <Spacer spacing="sm4" />
                  </>
                )}
                <S.TagContainer>
                  {item.tags.split(' ')?.map((tag: string) =>(
                    <S.Tag key={tag}>
                      <BodyText textSize="f1">
                        {tag}
                      </BodyText>
                    </S.Tag>
                  ))}
                </S.TagContainer>
                <Spacer spacing="sm3" />
                </>
              )}
              <RichText
                document={item.content.richText}
                textColor={backgroundColor === 'navy' ? 'white' : 'navy'}
                maxWidth="100%"
                marginBottom="md1"
                css={`white-space: break-spaces;`}
              />
            </S.ContentContainer>
          </S.GridContainer>
        ))}
      </S.SubContainer>
    </S.Container>
  )
};

export default ImageText;
