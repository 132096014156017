import React, { FC } from "react";
import { up } from "styled-breakpoints";
import { useBreakpoint } from "styled-breakpoints/react-styled";
import BodyText from "../../BodyText";
import Link from "../../Link";
import Subnav from "./Subnav";
import { MenuData } from "../Navigation";
import Cart from "../Cart";
import * as S from "./DesktopNav-styled";

interface DesktopNaigationProps {
  onShopClick: () => void;
  isSubnavOpen: boolean;
  data?: MenuData;
  backgroundColor: string;
}

const DesktopNavigation: FC<DesktopNaigationProps> = ({
  data,
  onShopClick,
  isSubnavOpen,
  backgroundColor,
}) => {
  const isLaptopUp = useBreakpoint(up("laptop"));
  const textColor = backgroundColor === "navy" ? "offWhite" : "navy";

  return (
    <>
      <S.Container backgroundColor={backgroundColor}>
        <Link url='/'>
          <S.Logo
            aria-hidden='true'
            height='8.25rem'
            width={isLaptopUp ? "23.063rem" : "18.063rem"}
            fill={backgroundColor === "navy" ? "#F5F3EE" : "#001831"}
          />
        </Link>
        <S.SubContainer>
          <S.ShopSection>
            {data?.menu_links?.map((items) => {
              const isGiftSets = items.label === "Gift Sets";
              const highlight = isSubnavOpen ? "midGray" : textColor;

              return (
                <S.NavItem key={items.label}>
                  {items.label === "Shop" ? (
                    <S.Button type='button' onClick={onShopClick}>
                      <BodyText fontFamily='parnaso' textColor={textColor}>
                        {items.label}
                      </BodyText>
                    </S.Button>
                  ) : (
                    <Link
                      url={`${items.link?.url}`}
                      activeStyle={{ fontWeight: "bold" }}
                    >
                      <BodyText
                        fontFamily='parnaso'
                        textColor={isGiftSets ? "gold" : highlight}
                        style={{ fontWeight: "inherit" }}
                      >
                        {items.label}
                      </BodyText>
                    </Link>
                  )}
                </S.NavItem>
              );
            })}
          </S.ShopSection>
          <S.ProfileSection>
            <S.NavItem
              as={Link}
              url='https://account.orcecosmetics.com/account'
            >
              <BodyText fontFamily='parnaso' textColor={textColor}>
                Account
              </BodyText>
            </S.NavItem>
            <S.NavItem>
              <Cart textColor={textColor} />
            </S.NavItem>
          </S.ProfileSection>
        </S.SubContainer>
      </S.Container>
      {isSubnavOpen && (
        <Subnav
          isOpen={isSubnavOpen}
          column1={data?.submenu_column_1}
          column2={data?.submenu_column_2}
          adImage={data?.ad_image?.gatsbyImageData}
          adButtonLink={data?.ad_button_link}
          adButtonLabel={data?.ad_button_label}
          onClick={onShopClick}
          backgroundColor={backgroundColor}
        />
      )}
    </>
  );
};

export default DesktopNavigation;
