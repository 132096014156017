import React, { FC, useRef, useState, useEffect, ReactElement } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  width: 100%;
  overflow-x: scroll;
  cursor: pointer;

  &::-webkit-scrollbar{
    display: none;
  }
`;

interface ScrollerProps {
  children: ReactElement
};

const Scroller: FC<ScrollerProps> = ({ children }) => {
  const [mouseDown, setMouseDown] = useState<boolean>();
  const [startX, setStartX] = useState<number | undefined>();
  const [scrollLeft, setScrollLeft] = useState<number | undefined>();
  const ref: any = useRef();

  useEffect(() => {
    const startDragging = (e: MouseEvent) => {
      setMouseDown(true)
      e.preventDefault();
      setStartX(e.pageX - (ref?.current?.offsetLeft ?? 0));
      setScrollLeft(ref?.current?.scrollLeft);
    };
  
    const stopDragging = () => {
      setMouseDown(false);
    }
  
    const handleDragging = (e: MouseEvent) => {
      e.preventDefault();
      if (!mouseDown) return;
      const x = e.pageX - (ref?.current?.offsetLeft ?? 0);
      const scroll = x - (startX ?? 0);
      if (ref?.current) {
        ref.current.scrollLeft = (scrollLeft ?? 0) - scroll;
      }
    }
  
    if (ref?.current) {
      ref.current.onmousedown = startDragging;
      ref.current.onmousemove = handleDragging;
      ref.current.onmouseup = stopDragging;
      ref.current.onmouseleave = stopDragging;
    }  
  })



  return (
    <Container ref={ref}>
      {children}
    </Container>
  );
};

export default Scroller;
