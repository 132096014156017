import React, { FC } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import CollectionsGrid from '../../components/CollectionsGrid';
import RichText from '../../components/RichText';
import Spacer from '../../components/Spacer';
import Seo from '../../components/Seo';

interface CollectionsPageProps {
  data: any;
};

export const Container = styled.section`
  max-width: 1440px;
  margin: auto;
  padding: 3.2rem 1.5rem;

  ${up('tablet-portrait')} {
    padding: 4rem 6.735rem 10.0625rem;;
  }

  ${up('laptop')} {
    padding: 4rem 9.735rem 10.0625rem;;
  }
`;

const CollectionsSection: FC<CollectionsPageProps> = ({ data: { primary, items } }) => {
  const title = primary.title;
  
  return (
      <Container>
        <RichText
          document={title.richText}
          fontFamily="parnaso"
          textSize={[null, "f8", "f10"]}
        />
        <Spacer spacing="md2" />
        <CollectionsGrid data={items} />
      </Container>
  )
}

export default CollectionsSection;


