import React, { FC, useState } from 'react';
import { FooterProps } from '../Footer';
import Plus from '../../../assets/Plus';
import Minus from '../../../assets/Minus';
import Logo from '../../../assets/Logo';
import BodyText from '../../BodyText';
import Spacer from '../../Spacer';
import Link from '../../Link';
import NewsLetterForm from '../../NewsLetterForm';
import * as S from './MobileFooter-styled';

export const MobileFooter: FC<FooterProps> = ({
  exploreList,
  connectList,
  resourcesList,
  year,
  formDescription,

}) => {
  const [isExploreOpen, setIsExploreOpen] = useState(false);
  const [isSocialOpen, setIsSocialOpen] = useState(false);
  const [isResourcesOpen, setIsResourcesOpen] = useState(false);

  const ExploreMenu = (
    <S.SubNavContainer isOpen={isExploreOpen}>
      <Spacer spacing="sm2" />
      {exploreList.map((item, index) => {
        if (item.name.toLowerCase() === 'shop') return;
        return (
          <Link url={item.link} key={`${item.name}-${index}`}>
            <BodyText textColor="white" >
              {item.name}
            </BodyText>
          </Link>
        )
      })}
      <Spacer spacing="md3" />
    </S.SubNavContainer>
  );
  
  const ConnectMenu = (
    <S.SubNavContainer isOpen={isSocialOpen}>
      <Spacer spacing="sm2" />
      {connectList.map((item, index) => (
        <Link url={item.link} key={`${item.name}-${index}`}>
          <BodyText textColor="white" >
            {item.name}
          </BodyText>
        </Link>
      ))}
      <Spacer spacing="md3" />
    </S.SubNavContainer>
  )
  
  const ResourceMenu = (
    <S.SubNavContainer isOpen={isResourcesOpen}>
      <Spacer spacing="sm2" />
      {resourcesList.map((item, index) => (
        <Link url={item.link} key={`${item.name}-${index}`}>
          <BodyText textColor="white" >
            {item.name}
          </BodyText>
        </Link>
      ))}
      <Spacer spacing="md3" />
    </S.SubNavContainer>
  )

  return (
    <S.Container>
      <S.SubContainer>
        <BodyText
          textColor="white"
          fontFamily="parnaso"
          textSize={["f8", "f9"]}
          css={`
            white-space: break-spaces;
            line-height: 1.1;
          `}
        >
          HERE <i>you</i> ARE CELEBRATED, ACKNOWLEDGED, AND SEEN.
        </BodyText>
        <Spacer spacing="md3" />
        <BodyText textColor="white">
          {formDescription}
        </BodyText>
        <Spacer spacing="md3" />
        <NewsLetterForm />
        <Spacer spacing="md3" />
      </S.SubContainer>
      <Spacer spacing="md3" />
      <S.MenuContainer>
        <S.Button as={Link} url="/collections">
          <BodyText textColor="white">
            SHOP
          </BodyText>
        </S.Button>
        <S.Button as={Link} url="mailto:info@orcecosmetics.com">
          <BodyText textColor="white">
            CONTACT
          </BodyText>
        </S.Button>
        <S.Button onClick={() => setIsExploreOpen(!isExploreOpen)}>
          <BodyText textColor="white">
            EXPLORE
          </BodyText>
          {isExploreOpen ? <Minus stroke="white" /> : <Plus stroke="white" />}
        </S.Button>
        {ExploreMenu}
        <S.Button onClick={() => setIsSocialOpen(!isSocialOpen)}>
          <BodyText textColor="white">
            SOCIAL
          </BodyText>
          {isSocialOpen ? <Minus stroke="white" /> : <Plus stroke="white" />}
        </S.Button>
        {ConnectMenu}
        <S.Button onClick={() => setIsResourcesOpen(!isResourcesOpen)}>
          <BodyText textColor="white">
            SUPPORT
          </BodyText>
          {isResourcesOpen ? <Minus stroke="white" /> : <Plus stroke="white" />}
        </S.Button>
        {ResourceMenu}
      </S.MenuContainer>
      <Spacer spacing="md3" />
      <S.SubContainer> 
        <BodyText textColor="white">
          {`©${year} Orcé Cosmetics`}
        </BodyText>
      </S.SubContainer>
      <S.LogoContainer>
        <Logo fill="white" width="113%" height="100%" />
      </S.LogoContainer>
    </S.Container>
  )
};


export default MobileFooter;

