import { css } from 'styled-components';
import { hexToRGBA } from './colors';

export const ButtonReset = css`
  padding: 0;
  border-style: none;
  border-width: 0;

  background-color: transparent;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

export interface FocusProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  useInsetFocus?: boolean;
}

export const FocusCSS = css<FocusProps>`
  outline-width: 0px;

  box-shadow: ${({ isDisabled, isLoading, useInsetFocus, theme: { colors } }): string =>
    isDisabled || isLoading ? '' : `${useInsetFocus ? 'inset ' : ''}${hexToRGBA(colors.active || '')} 0px 0px 0px 3px`};

  transition: ${({ theme: { transitions } }): string => transitions?.focus ?? ''};
`;

export const Focus = css<FocusProps>`
  &:focus,
  &:active {
    ${FocusCSS};
  }
`;

// To be used for any elements that need to be visually hidden,
// but still readable by screen readers, for more info see:
// https://webaim.org/techniques/css/invisiblecontent/
export const VisuallyHidden = css`
  position: absolute;
  left: -10000px;
  top: auto;

  width: 1px;
  height: 1px;

  overflow: hidden;
`;
