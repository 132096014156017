import styled from "styled-components";
import { up } from "styled-breakpoints";

export const Container = styled.div`
  width: 100%;
  max-width: 1440px;
  margin: auto;
  padding: 1rem;

  ${up('phone')} {
    padding: 1.5rem;
  }

  ${up('tablet-portrait')} {
    padding: 2rem 6rem;
  }

  ${up('laptop')} {
    padding: 2rem 9.735rem;
  }

`;

export const SubContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 1.5rem;
`;

export const GridContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  column-gap: 1.5rem;

  ${up('tablet-landscape')} {
    grid-template-columns: 1fr 1fr;
  }
`;

export const ImageContainer = styled.div<{ alignment?: string }>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  object-fit: cover;
  padding: 0;

  ${up('tablet-portrait')} {
    display: none;
  }

  ${up('tablet-landscape')} {
    display: flex;
    flex-direction: column;
    justify-content: center;
    object-fit: cover;
    padding: 0;
    grid-area: 1 / ${({ alignment }) => alignment === 'right' ? '2' : '1'};
  }
`;

export const ContentContainer = styled.div<{ backgroundColor: string }>`
  width: 100%:
  height: 100%;
  padding: 1.5rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${up('tablet-portrait')} {
    padding: 1rem;
  }

  ${up('tablet-landscape')} {
    outline: solid 1px ${({ backgroundColor }) =>  backgroundColor  === 'navy' ? 'white' : 'black'};
    padding: 0 min(4rem, 10%);
  }
`;

export const TagContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Tag = styled.div`
  padding: 6px 12px;
  border: solid 1px ${({ theme }) => theme.colors.navy};
  border-radius: 5px;
  width: fit-content;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  ${up('tablet-landscape')} {
    margin-right: 0.25rem;
    margin-bottom: 0.25rem;
  }
`;