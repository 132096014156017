import React, { useState, useEffect, FC } from 'react';
// @ts-ignore
import { StoreContext, getSum } from '../../../context/StoreContext';  
import BodyText from '../../BodyText';
import ItemCard from './ItemCard';
import Spinner from '../../Spinner';
import Loader from '../../Loader';
import * as S from './Cart-styled';
import RouteCard from './RouteCard';


declare global { 
  interface Window {
    routeapp: any;
  }
};

interface CartProps  {
  textColor?: 'navy' | 'offWhite';
};

const Cart: FC<CartProps> = ({ textColor="offWhite" }) => {
  const {
    checkout,
    loading,
    insurancePrice,
    withInsurance,
    routeProtectionData: { 
      featuredImage
    },
    handleCheckout,
    fullPageLoading,
  } = React.useContext<any>(StoreContext);
  const { lineItems } = checkout ?? {};
  const [isOpen, setIsOpen] = useState(false);
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const handleCartClick = () => setIsOpen(!isOpen);


  useEffect(() => {
    if (lineItems) {
      const quantitySum = lineItems.reduce((accumulator: any, next: any) => {
        if (next.customAttributes[0].value === 'Insurance') return accumulator;
        return accumulator + next.quantity;
      }, 0);
      setTotalQuantity(quantitySum);
      const amountSum = getSum(lineItems);
      setTotalAmount(amountSum);
    }
  },[lineItems]);

  useEffect(() => {
    if (!isOpen) return;
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    if (body && html) {
      html.style.overflow = 'hidden';
    }
    return () => {
      if (body && html) {
        html.style.overflow = 'visible';
      }
    }
  }, [isOpen]);

  return (
    <>
      <S.ButtonContainer>
        <S.Button type="button" onClick={handleCartClick}>
          <BodyText fontFamily="parnaso" textColor={textColor}>
            Cart
          </BodyText>
          {totalQuantity > 0 && (
            <S.QuantityIndicator key={totalQuantity}>
              <BodyText textSize="f1" textColor="white" alignment="center">
                {totalQuantity}
              </BodyText>
            </S.QuantityIndicator>
          )}
        </S.Button>
      </S.ButtonContainer>
      {isOpen && (
        <>
        <S.Blur onClick={handleCartClick} />
        <S.CheckoutContainer>
          <S.SubContainer>
            <S.CheckoutHeader>
              <BodyText textSize={['f2', 'f3']}>
                {`BAG: ${totalQuantity}`}
              </BodyText>
              <BodyText fontFamily="parnaso" textSize={[null, 'f2', 'f3']}>
                {totalAmount > 0 && totalAmount < 50 ? (
                 `$${50 - totalAmount} away from free shipping` 
                ) : null}       
              </BodyText>
              <S.Button type="button" onClick={handleCartClick}>
                <BodyText textSize={['f2', 'f3']}>
                  CLOSE
                </BodyText>
              </S.Button>
            </S.CheckoutHeader>
            {totalQuantity ? (
              <S.List>
                {
                  checkout?.lineItems?.map((item:any, index: number) => {
                    if (item?.customAttributes?.[0]?.value === 'Insurance') return null;
                    return (
                      <li key={item?.title}>
                        <ItemCard
                          title={item?.customAttributes?.[0]?.value}
                          variant={item?.title}
                          img={item?.variant?.image?.src}
                          quantity={item?.quantity}
                          price={item?.variant?.price?.amount}
                          variantId={item?.variant?.id}
                          productType={item?.productType}
                        />
                      </li>
                    )
                  })
                }
                <li>
                  <RouteCard
                    image={featuredImage.gatsbyImageData}
                    price={insurancePrice}
                    loading={loading}
                  />
                </li>
              </S.List>
            ) : (
              <S.CopyContainer>
                <BodyText
                  fontFamily="parnaso"
                  textSize="f10"
                  lineHeight="1.1"
                  maxWidth="400px"
                >
                  THERE'S <i>nothing</i> IN YOUR CART!
                </BodyText>
              </S.CopyContainer>
            )}
            <S.CheckoutButton onClick={handleCheckout} type="submit" >
              <BodyText textColor="white">
                {loading ? <Loader /> : `CHECKOUT    ●    $${withInsurance ? totalAmount + insurancePrice : totalAmount}`}
              </BodyText>
            </S.CheckoutButton>
          </S.SubContainer>
        </S.CheckoutContainer>

        </>
      )}

      {fullPageLoading && (
        <Spinner />
      )}
    </>
  );
};

export default Cart;
