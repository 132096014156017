import React, { FC } from 'react';
import * as S from './CircularProgressBar-styled';

interface CircularProgressBar {
  percentage: number
  inView: boolean;
  delay: number
}

const CircularProgressBar: FC<CircularProgressBar> = ({ percentage, inView, delay }) => {
  // this animation utilizes 2 half circles hence / 2
  const percentToDegHalf = ((360/100) * percentage) / 2;
  // pointer should go around the whole circle;
  const percentToDeg = ((360/100) * percentage);
  return (
    <S.CircleContainer>
      <S.CircleWrap>
          <S.CircleOutline />
          <S.CircleFull
            deg={`${percentToDegHalf}deg`}
            $fill={`fill-${Math.floor(percentage)}`}
            inView={inView}
          >
            <S.Fill
              deg={`${percentToDegHalf}deg`}
              $fill={`fill-${Math.floor(percentage)}`}
              inView={inView}
            />
          </S.CircleFull>
          <S.CircleHalf>
            <S.Fill
              deg={`${percentToDegHalf}deg`}
              $fill={`fill-${Math.floor(percentage)}`}
              inView={inView}
            />
          </S.CircleHalf>
          <S.InnerCircle />
          <S.CirclePointer
            deg={`${percentToDeg}deg`}
            $fill={`fill-pointer-${Math.floor(percentToDeg)}`}
            inView={inView}
          />
        <S.RadialGradient />
        <S.RadialPulse delay={delay}  />
        <S.InnerContainer />
      </S.CircleWrap>
    </S.CircleContainer> 
  )
};

export default CircularProgressBar;