import React, { FC, ReactNode, ElementType } from 'react';
import styled from 'styled-components';
import { VisuallyHidden } from '../../utils/css';
import { BaseProps } from '../../system';

export interface ScreenReaderOnlyProps extends Pick<BaseProps, 'dataId' | 'id'> {
  as?: ElementType;
  children: ReactNode;
}

const BaseScreenReaderOnly = styled.span`
  ${VisuallyHidden};
`;

const ScreenReaderOnly: FC<ScreenReaderOnlyProps> = ({ as = 'span', children, dataId, id }) => (
  <BaseScreenReaderOnly as={as} data-id={dataId} id={id}>
    {children}
  </BaseScreenReaderOnly>
);

export default ScreenReaderOnly;
