import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import LogoSVG from '../../../assets/Logo';

export const Container = styled.div<{ backgroundColor: string }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  background-color: ${({ theme, backgroundColor }): string => (
    backgroundColor === 'navy' ? theme.colors.navy : theme.colors.offWhite
    )};
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding-left: 7.5rem;
  height: 3.75rem;

  ${up('laptop')} {
    padding-left: 9.313rem;
  }
`;

export const ShopSection = styled.ul`
  display: inline-box
`;

export const ProfileSection = styled.ul`
  list-type: none;
  display: inline-box;
`;

export const Button = styled.button`
  display: inline-block;
`;

export const Logo = styled(LogoSVG)`
  position: absolute;
  transform: rotate(90deg);
  left: -6.5rem;		
  top: 4.7rem;
  z-index: 100;

  ${up('laptop')} {
    left: -8.25rem;
    top: 7.36rem;
  }
`;

export const NavItem = styled.li`
  padding-right: 1rem;
`
