import styled from 'styled-components';

export const Modal = styled.dialog`
  overflow-x: hidden;
  padding: 0;
  border: none;
  

  ::backdrop {
    background-color: ${({ theme }) => theme.colors.navy};
    opacity: 0.7;
  }
`;


export const Container = styled.div`
  background-color: ${({ theme }) => theme.colors.offWhite};
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 375px;
  min-width: 320px;
  bottom: 0;
  left: 0;

  :focus {
    outline-color: ${({ theme }) => theme.colors.gold};
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  overflow: hidden;
`;

export const LogoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 45px;
`;

export const LogoSubContainer = styled.div`
  position: absolute;
  bottom: -80px;
  left: -35px;
  right: -40px;
`;

export const ImageContainer = styled.div`
  max-height: 500px;
`;

export const Img = styled.img`
  max-height: 250px;
`;

export const ButtonContainer = styled.div`
  position: absolute;
  top: 5px;
  right: 5px;
`;

export const Button = styled.button`
`;

