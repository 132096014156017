import React, { FC } from 'react';
import { up } from 'styled-breakpoints';
import { useBreakpoint } from 'styled-breakpoints/react-styled';
import styled from 'styled-components';
import RichText from '../../components/RichText';
import Spacer from '../../components/Spacer';

const Container = styled.div`
  padding: 0 1rem;

  ${up('phone')} {
    padding: 0 1.5rem;
  }

  ${up('tablet-portrait')} {
    padding: 0 1.875rem;
  }
`;


const TextColumns = styled.div`
  column-count: 1;
  max-width: 800px;
  margin: auto;
  column-gap: 1.5rem;

  ${up('tablet-portrait')} {
    column-count: 2;
    padding: 0 4.5rem;
  }

  > p {
    padding-bottom: 1rem;
  }
`;


const DoubleColumnText: FC<{ data: any }> = ({ data: { primary } }) => {
  const { backgroundColor, paragraph, title } = primary;
  const isTabletUp = useBreakpoint(up('tablet-portrait'));
  const textColor = backgroundColor === 'navy' ? 'white' : 'navy';

  return (
    <Container>
      <Spacer spacing={isTabletUp ? 'lg1' : 'md1'} />
      <RichText
        document={title.richText}
        textColor={textColor}
        textSize={[ null, "f9", "f13", "f15", "f16"]}
        alignment={isTabletUp ? 'center' : 'left'}
      />
      <Spacer spacing={isTabletUp ? 'lg3' : 'md2'} />
      <TextColumns>
        <RichText
          document={paragraph.richText}
          textColor={textColor}
        />
      </TextColumns>
      <Spacer spacing={isTabletUp ? 'lg1' : 'md1'} />
    </Container>
  );
};


export default DoubleColumnText;
