import React, { FC } from 'react';
// @ts-ignore
import { StoreContext } from '../../../../context/StoreContext';  
import BodyText from '../../../BodyText';
import Spacer from '../../../Spacer';
import * as S from './ItemCard-styled';


interface ItemCardProps {
  img?: string;
  title?: string;
  quantity?: number;
  price?: string;
  variantId?: string;
  variant?: string;
  productType?: string;
}


const ItemCard: FC<ItemCardProps> = ({ img, title, quantity = 0, price, variantId, variant, productType }) => {
  const { addVariantToCart } = React.useContext(StoreContext);

  const handleIncreaseButton = () => {
    // @ts-ignore
    addVariantToCart(variantId, 1, productType, price);
  }

  const handleDecreaseButton = () => {
    // @ts-ignore
    addVariantToCart(variantId, -1, productType, price);
  }

  const formattedPrice = price?.includes('.0') ? price.replace('.0', '') : price;
  return (
    <S.Container>
      <S.ImageContainer>
        <img src={img} style={{ objectFit: 'cover', width: '100%', height: '100%' }}/>
      </S.ImageContainer>
      <Spacer spacing="md2" direction="vertical" />
      <S.MetaContainer>
        <S.Flex>
          <S.ProductInfoContainer>
           <BodyText maxWidth="calc(100% - 10px)" textTransform="uppercase" textSize={[null, 'f2', 'f3']} >
              {title}
            </BodyText>
            <BodyText maxWidth="calc(100% - 10px)" textTransform="uppercase" textSize='f1' textColor="midGray" >
              {variant}
            </BodyText>
          </S.ProductInfoContainer>
          <BodyText>
            {`$${formattedPrice}`}
          </BodyText>
        </S.Flex>
        <S.QuantityContainer>
          <S.DecreaseButton onClick={handleDecreaseButton}>
            <BodyText textSize="f3" alignment="center">
              &#xFF0D;
            </BodyText>
          </S.DecreaseButton>
          <BodyText width="37px" alignment="center">
            {quantity}
          </BodyText>
          <S.IncreaseButton onClick={handleIncreaseButton}>
            <BodyText textSize="f3" alignment="center">
              &#xFF0B;
            </BodyText>
          </S.IncreaseButton>
        </S.QuantityContainer>
      </S.MetaContainer>
    </S.Container>

  )
};

export default ItemCard;