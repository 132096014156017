import styled from 'styled-components';
import { up } from 'styled-breakpoints';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  padding: 1.5rem 1rem;

  ${up('phone')} {
    padding: 1.5rem;

  }

  ${up('tablet-portrait')} {
    padding: 2rem 6rem;
  }

  ${up('laptop')} {
    padding: 2rem 9.735rem;
  }
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: column-reverse;


  ${up('tablet-portrait')} {
    display: grid;
    grid-template-columns: 1fr min(4%,40px) 1fr;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  grid-column: 1 / 3;
  grid-row: 1 / 2;
  z-index: 1;

`;

export const ImageWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 20px;

  ${up('tablet-portrait')} {
    margin-right: auto;
    grid-column: 2 / 4;
    grid-row: 1 / 2;
  }
`;

export const MobileImageOutline = styled.div`
  position: absolute;
  border: 1px solid white;
  aspect-ratio: 1 / 1;
  width: 100%;
  margin-left: 40px;
  height: calc(100% + 40px);
  
  ${up('tablet-portrait')} {
    display: none;
  }
`;

export const ContentContainer = styled.div`
  position: relative;
  width: 100%;

  ${up('tablet-portrait')} {
    width: 97%;
  }
`;

export const ImageContainer = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const OutlineContainer = styled.div<{ backgroundColor: string}>`
  outline: solid 1px ${({ theme, backgroundColor }) => backgroundColor === 'navy' ? 'white' : theme.colors.navy};
  z-index: 1;
  width: 100%;
  max-width: 555px;
  height: 100%;
  max-height: 555px;
  aspect-ratio: 1/1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transform: rotate(270deg);
  margin: auto;
`;

export const TitleContainer = styled.div<{ backgroundColor: string}>`
  display: none;

  ${up('tablet-landscape')} {
    padding: 14% 0px;
    width: 100%;
    outline: 1px solid ${({ theme, backgroundColor }) => backgroundColor === 'navy' ? 'white' : theme.colors.navy};
    display: flex;
    justify-content: center;
    border-start-start-radius: 21% 50%;
    border-start-end-radius: 21% 50%;
    border-end-start-radius: 21% 50%;
    border-end-end-radius: 21% 50%;
  }
`;

export const MobileTitleContainer = styled.div`
  padding-top: 4rem;
  padding-bottom: 1.5rem;

  ${up('tablet-portrait')} {
    display: none;
  }
`;

export const DescriptionContainer = styled.div`
  ${up('tablet-portrait')} {
    position: absolute;
    top:50%;
    left: 35%;
    transform: translate(-50%, -50%);
    left: 50%;
    width: 85%;
  }

  ${up('tablet-landscape')} {
    left: 35%;
    width: 45%;
  }
`;

export const HeroTitleContainer = styled.div`
  display: none;

  ${up('tablet-portrait')} {
    display: inline-block;
  }
`;