import * as React from 'react'
import {
  PrismicPreviewProvider,
  componentResolverFromMap,
} from 'gatsby-plugin-prismic-previews'
import linkResolver from './src/utils/linkResolver'
import PageTemplate from './src/templates/Page';
import { StoreProvider } from './src/context/StoreContext';

export const wrapRootElement = ({ element }) => (
  <PrismicPreviewProvider
    repositoryConfigs={[
      {
        repositoryName: process.env.GATSBY_PRISMIC_REPO_NAME,
        linkResolver,
        componentResolver: componentResolverFromMap({
          page: PageTemplate,
        }),
      },
    ]}
  >
    <StoreProvider>
      {element}
    </StoreProvider>
  </PrismicPreviewProvider>
);
