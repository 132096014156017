import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { Container as SectionContainer } from '../../styles/SectionContainer';
import BodyText from '../../components/BodyText';

interface ContainerProps {
  inView: boolean;
}

export const Container = styled.section<ContainerProps>`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 1rem;
  margin: auto;

  ${up('phone')}{
    padding: 0 1.5rem;
  };
  
  ${SectionContainer};
`;

export const SlideContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  padding: 0 0.5rem;
`;

export const SliderWrapper = styled.div`
  position: relative;
`;


export const ImageContainer = styled.div`
  max-width: 22.5rem;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const MetaContainer = styled.div`
  display: flex;
  justify-content: space-between;
  max-width: 22.5rem;
  width: 100%;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const ArrowButton = styled.button`
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  ${up('tablet-portrait')} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

export const Circle = styled.div`
  background-color: ${({ theme }) => theme.colors.gold};
  border-radius: 50%;
  width: 0.9375rem;
  height: 0.9375rem;
`;


export const MobileLink = styled(BodyText)`
  background-color: ${({ theme }) => theme.colors.navy};
  color: ${({ theme }) => theme.colors.offWhite};
  padding: 0.9rem 2.4rem;
  width: 100%;
  outline: 1px solid ${({ theme }) => theme.colors.navy};
  transition: all .1s ease-in-out;
  text-align: center;
  margin-top: 20px;

  &:hover,
  :focus {
    background-color: ${({ theme }) => theme.colors.offWhite};
    color: ${({ theme }) => theme.colors.navy}
  }

  ${up('tablet-portrait')} {
    background-color: transparent;
    outline: none;
    transition: none;
    color: ${({ theme }) => theme.colors.navy};
    margin-top: 0;
    padding: 0;

    &:hover,
    focus {
      background-color: transparent;
      color: ${({ theme }) => theme.colors.navy}
    }
  };
`;