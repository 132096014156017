import { css } from 'styled-components';
import { up } from 'styled-breakpoints';
import { FadeinUp } from '../../styles/FadeinUp';

interface ContainerProps {
  inView?: boolean;
}


export const Container = css<ContainerProps>`
  max-width: 1440px;
  ${FadeinUp};

  ${up('tablet-portrait')} {
    padding: 0 6.735rem;
  }

  ${up('tablet-landscape')} {
    padding: 0 9.735rem;
  }
`;