import React, { ReactNode, FC } from 'react';
import { Link as GatsbyLink } from 'gatsby';
import styled from 'styled-components';

interface LinkProps {
  url: string;
  type?: string;
  children: ReactNode;
  className?: any;
  isButton?: boolean | null;
  target?: string;
  activeStyle?: any;
}


const LinkComponent = styled.a<{ isButton?: boolean; }>`
  display: block;
`;


const Link: FC<LinkProps> = ({ url, children, className, target = '_self', activeStyle  }) => {
  const isInternalLink = url?.startsWith('https:///');
  let modifiedURL = url;

  if (isInternalLink) modifiedURL = url?.slice(8);

  return (!isInternalLink) ? (
    <LinkComponent href={url} className={className} target={target}>
      {children}
    </LinkComponent>
  ) : (
    <LinkComponent
      as={GatsbyLink}
      to={isInternalLink ? modifiedURL : url}
      className={className}
      target={target}
      activeStyle={activeStyle}
      partiallyActive={true}
    >
      {children}
    </LinkComponent>
  )
};

export default Link;