import { css } from 'styled-components';

interface FadeinUpProps {
  inView?: boolean;
}

export const FadeinUp = css<FadeinUpProps>`
  opacity: 0;
  transform: translateY(7px);
  transition-property: opacity, transform;
  transition-duration: 340ms, 500ms;
  transition-timing-function: ease-in-out;

  ${({ inView }) => inView ? css` transform: translateY(0); opacity: 1; ` : null}
`;