import React, { FC, useState } from 'react';
import PlusIcon from '../../assets/Plus';
import MinusIcon from '../../assets/Minus';
import * as S from './Accordion-styled';

interface AccordionProps {
  outlineColor?: string;
  title?: any;
  content?: any;
  isLast?: boolean;
  isAccordionOpen?: boolean;
  hasPadding?: boolean | null;
}

const Accordion: FC<AccordionProps> = ({ outlineColor = 'navy', title, content, isAccordionOpen, hasPadding = true }) => {
  const [isOpen, setIsOpen] = useState(isAccordionOpen);

  const handleClick = () => {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <S.Button onClick={handleClick} hasPadding={hasPadding}>
        {title}
        {isOpen ? <MinusIcon stroke="navy" height="19" /> : <PlusIcon stroke="navy" />}
      </S.Button>
      {isOpen && (
        <S.ContentContainer outlineColor={outlineColor}>
          {content}
        </S.ContentContainer>
      )}
    </>
  )

};

export default Accordion;
