import React, { FC } from 'react';
import styled from 'styled-components';
import { up } from 'styled-breakpoints';
import { GatsbyImage } from 'gatsby-plugin-image';
import BodyText from '../BodyText';
import RichText from '../RichText';
import Spacer from '../Spacer';
import Link from '../Link';

export const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(290px, 1fr));
  column-gap: 1.5rem;
  row-gap: 3.6875rem;
`;

const CardContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  ${up('tablet-portrait')} {
    flex-direction: row;
  }
`;

const PriceContainer = styled.div`
  display: flex;
`;

interface GridCardProps {
  image: any,
  title: string,
  link?: string,
  description?: any,
  price?: string,
  originalPrice?: string,
}

const GridCard: FC<GridCardProps> = ({ image, title, link, description, price, originalPrice }) => {
  return link ? (
    <Link url={link}>
      <CardContainer>
        <GatsbyImage image={image.gatsbyImageData} alt="" />
        <Spacer spacing="md1" />
        <TitleContainer>
          {title && (
            <>
              <BodyText textColor="navy" textTransform="uppercase"  textSize="f3" marginBottom="sm1">
                {title}
              </BodyText>
              <PriceContainer>
              <BodyText>
                ${price}
              </BodyText>
              {originalPrice && (
                <>
                <Spacer spacing="sm3" direction="vertical" />
                <BodyText css={`text-decoration: line-through;`} textColor="midGray">
                  ${originalPrice}
                </BodyText>
                </>
              )}
              </PriceContainer>
            </>
          )}
        </TitleContainer>
        <Spacer spacing="sm3" />
        {description && (
          <RichText document={description.richText} />
        )}
      </CardContainer>
    </Link>
  ) : (
    <CardContainer>
      <GatsbyImage image={image.gatsbyImageData} alt="" />
      <Spacer spacing="md1" />
      <TitleContainer>
        {title && (
          <>
            <BodyText textColor="navy" textTransform="uppercase"  textSize="f3" marginBottom="sm1">
              {title}
            </BodyText>
            <PriceContainer>
            <BodyText>
              ${price}
            </BodyText>
            {originalPrice && (
              <>
              <Spacer spacing="sm3" direction="vertical" />
              <BodyText css={`text-decoration: line-through;`} textColor="midGray">
                ${originalPrice}
              </BodyText>
              </>
            )}
            </PriceContainer>
          </>
        )}
      </TitleContainer>
      <Spacer spacing="sm3" />
      {description && (
        <RichText document={description.richText} />
      )}
    </CardContainer>
  )

};


const CollectionsGrid: FC<{ data: Record<string, any> }> = ({ data }) => {
  return (
    <Grid>
      {data.map((card: any) => (
        <GridCard
          image={card.card_image}
          title={card.title ?? card.itemTitle}
          link={card?.link}
          description={card?.description}
          price={card?.price}
          originalPrice={card?.original_price}
          key={card.title?? card.itemTitle}
        />
      ))}
    </Grid>
  )
};

export default CollectionsGrid;